import React, { useState, useEffect, useCallback, useMemo } from "react";
import ProjectServices from "../../../services/ProjectServices";
import CookieManager from "../common/CookieManager";
import CommonServices from "../../../services/CommonServices";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
// import { useLocation, useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Pagination } from "antd";
import Preloader from "../../Preloader";

var ProjectServicesObj = new ProjectServices();
var CommonServicesObj = new CommonServices();

function Shop() {
  const [productName, setProductName] = useState("");
  const defaultProductQuandity = 1;

  const [ProjectDetails, setProjectDetails] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["products"]);
  const [isAdded, setIsAdded] = useState(false);
  const [Model, setModel] = useState({
    projectID: "",
  });

  const [Category, setCategory] = useState([]);
  //const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [products, setProducts] = useState([]);
  // const history = useHistory();
  const location = useLocation();
  const newCategoryID = location.state?.categoryID;
  const flag = location.state?.flag;
  const Secondflag = location.state;
  const [catID, setCatID] = useState(newCategoryID ?? 0);
  const [allCatID, setAllCatID] = useState([]);
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [dummyReseter, setDummyReseter] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 3000);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    CommonServicesObj.GetCategoryList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setAllCatID(res.data.map((e) => e.categoryID));
        setCategory([...res.data]);
        //
        // const categoryIDs = [...res.data]
        //   .map((category) => category.categoryID)
        //   .join(",");
        // setCatIDString(categoryIDs);
      }
    });
  }, []);

  useEffect(() => {
    if (newCategoryID) {
      handleCategoryChange(newCategoryID);
    } else if (Secondflag?.flag) {
      CommonServicesObj.GetCategoryList().then((res) => {
        if (res.data != null && res.data.length > 0) {
          const categoryIDs = [...res.data]
            .map((category) => category.categoryID)
            .join(",");
          fetchProductsFromCategoriesTab(categoryIDs);
          handlePageChange(1);
        }
      });
    } else {
      if (flag != true) {
        setSelectedCategories([]);
        handlePageChange(1);
      }
      fetchAllProducts();
    }
  }, [newCategoryID, Secondflag, dummyReseter]);

  const handleCategoryChange = (catID) => {
    setSelectedCategories((prevSelectedCategories) => {
      const updatedCategories = prevSelectedCategories.includes(catID)
        ? prevSelectedCategories.filter((id) => id !== catID)
        : [...prevSelectedCategories, catID];

      if (updatedCategories.length === 0) {
        fetchAllProducts();
      } else {
        fetchProductsByCategories(updatedCategories);
      }
      console.log("updatedCategories", updatedCategories);
      return updatedCategories;
    });
  };

  // const handleCategoryChange = (catID) => {
  //   setSelectedCategories(catID);

  //   const updatedCategories = selectedCategories.includes(catID)
  //     ? selectedCategories.filter((id) => id !== catID)
  //     : [...selectedCategories, catID];
  //   //   "selectedCategories after updatedCategories : ",
  //   //   selectedCategories
  //   // );
  //   setSelectedCategories(updatedCategories);

  //   if (updatedCategories.length === 0) {
  //     fetchAllProducts();
  //   } else {
  //     fetchProductsByCategories(updatedCategories);
  //   }
  // };

  const fetchAllProducts = () => {
    ProjectServicesObj.Client_GetProjectList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setProjectDetails([...res.data]);
        setTimeout(() => {
          $("#preloader").hide();
        }, 2000);
      }
    });
  };

  const fetchProductsByCategories = async (catID) => {
    handlePageChange(1);
    let Ids = catID.join(",");
    await CommonServicesObj.GetProductsByCategories(Ids)
      .then((res) => {
        if (res != null) {
          setProjectDetails(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProductsFromCategoriesTab = async (catID) => {
    const categoryIDs = catID.split(",");
    categoryIDs.forEach((categoryID) => {
      handleCategoryChangeTab(parseInt(categoryID));
    });

    // await CommonServicesObj.GetProductsByCategories(catID)
    //   .then((res) => {
    //     if (res != null) {
    //       setProjectDetails(res.data.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleCategoryChangeTab = (catID) => {
    console.log("handleCategoryChangeTab", catID);
    setSelectedCategories((prevSelectedCategories) => {
      if (prevSelectedCategories.includes(catID)) {
        const updatedCategories = prevSelectedCategories.map((id) =>
          id === catID ? catID : id
        );
        return updatedCategories;
      } else {
        const updatedCategories = [...prevSelectedCategories, catID];

        fetchAllProducts();
        // if (updatedCategories.length === 0) {
        //   fetchAllProducts();
        // } else {
        //   fetchProductsByCategories(updatedCategories);
        // }

        return updatedCategories;
      }
    });
  };

  const handleAddProduct = (addProduct, productName, quantity) => {
    setProductName(productName); // Set product name
    const result = addProduct(productName, quantity); // Add product with default quantity
    handleCartAlert(result);
  };

  const handleIconHover = (name) => {
    const products = cookies.products || [];
    const newVal = products.some((product) => product.name === name);
    setIsAdded(newVal);
  };

  const handleCartAlert = (e) => {
    if (e) {
      Swal.fire({
        title: "Product added Successfully!",
        text: "  ",
        icon: "success",
        timer: 1500, // Alert will close automatically after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        position: "top-end", // Position the alert in the top right corner
        toast: true, // Makes the alert look like a toast notification
      });
    } else {
      Swal.fire({
        title: "Product Already added",
        text: "",
        icon: "info",
        timer: 1500, // Alert will close automatically after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        position: "top-end", // Position the alert in the top right corner
        toast: true, // Makes the alert look like a toast notification
      });
    }
  };

  const navigate = useNavigate();

  const handleImageClick = (id) => {
    navigate("/product", {
      state: { productId: id },
      replace: true,
    });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;

  const paginatedData = ProjectDetails?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a style={{ marginRight: "20px" }}>Next</a>;
    }
    return originalElement;
  };

  return (
    <>
    <Preloader/>
      <CookieManager>
        {({ addProduct }) => (
          <div>
            {/* Shop Start */}
            <div className="container-fluid">
              <div className="row px-xl-5">
                {/* Shop Sidebar Start */}
                <div className="col-lg-3 col-md-4">
                  {/* Price Start */}
                  <h5 className="section-title position-relative text-uppercase mb-3">
                    <span className="bg-secondary pr-3">
                      Filter by Category
                    </span>
                  </h5>
                  <div className="bg-light p-4 mb-30">
                    <form>
                      {Category.map((category) => (
                        <div
                          className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"
                          key={category.categoryID}
                        >
                          <input
                            type="checkbox"
                            key={category.categoryID}
                            className="custom-control-input"
                            id={`price_${category.categoryID}`}
                            // value={selectedCategories?.includes(
                            //   category.categoryID
                            // )}
                            value={category.categoryID}
                            checked={selectedCategories?.includes(
                              category.categoryID
                            )}
                            onChange={() =>
                              handleCategoryChange(category.categoryID)
                            }
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`price_${category.categoryID}`}
                          >
                            {category.categoryName}
                          </label>

                          <span className="badge border font-weight-normal"></span>
                        </div>
                      ))}
                      <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"></div>
                    </form>
                  </div>
                  {/* Price End */}
                </div>
                {/* Shop Sidebar End */}

                {/* Shop Product Start */}
                <div className="col-lg-9 col-md-8">
                  <div className="row pb-3">
                    {/* <div className="col-12 pb-1">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <div>
                          <button className="btn btn-sm btn-light">
                            <i className="fa fa-th-large" />
                          </button>
                          <button className="btn btn-sm btn-light ml-2">
                            <i className="fa fa-bars" />
                          </button>
                        </div>

                        <div className="ml-2">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-sm btn-light dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              Sorting
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a className="dropdown-item" href="#">
                                Latest
                              </a>
                              <a className="dropdown-item" href="#">
                                Popularity
                              </a>
                              <a className="dropdown-item" href="#">
                                Best Rating
                              </a>
                            </div>
                          </div>

                          <div className="btn-group ml-2">
                            <button
                              type="button"
                              className="btn btn-sm btn-light dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              Showing
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a className="dropdown-item" href="#">
                                10
                              </a>
                              <a className="dropdown-item" href="#">
                                20
                              </a>
                              <a className="dropdown-item" href="#">
                                30
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div
                      className="row"
                      style={{
                        // width: "-webkit-fill-available",
                        // width: "-moz-available",
                        width: "100%",
                      }}
                    >
                      {paginatedData != null && paginatedData.length > 0 ? (
                        paginatedData?.map((v, i) => {
                          return (
                            <div
                              id={`Pdescriptions_${v.projectID}`}
                              className="col-lg-4 col-md-6 col-sm-6 pb-1"
                              key={v.projectID}
                            >
                              {" "}
                              <div className="product-item bg-light mb-4">
                                <div
                                  className="product-img  position-relative overflow-hidden d-flex justify-content-center align-items-center"
                                  style={{ height: "250px" }}
                                >
                                  {/* <img className="img-fluid w-100" src="/assets/img/product-1.jpg" alt="" /> */}

                                  <img
                                    className="img-fluid"
                                    src={
                                      process.env.REACT_APP_API_IMAGEPATH +
                                      v.imageUrl
                                    }
                                    // style={{ objectFit: "cover" }}
                                    height={200}
                                    width={200}
                                    alt="Thumb"
                                  />

                                  <div className="product-action">
                                    <a
                                      className="btn btn-outline-dark btn-square"
                                      href
                                      onClick={() =>
                                        handleAddProduct(
                                          addProduct,
                                          v.name,
                                          defaultProductQuandity
                                        )
                                      }
                                      onMouseEnter={() =>
                                        handleIconHover(v.name)
                                      }
                                      title={
                                        isAdded
                                          ? "Already added"
                                          : "Add to favorites"
                                      }
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      aria-hidden="true"
                                    >
                                      <i className="fa fa-shopping-cart" />
                                    </a>
                                    {/* <a
                                        className="btn btn-outline-dark btn-square"
                                        href
                                      >
                                        <i className="far fa-heart" />
                                      </a> */}
                                    {/* <a
                                        className="btn btn-outline-dark btn-square"
                                        href
                                      >
                                        <i className="fa fa-sync-alt" />
                                      </a> */}
                                    <a
                                      className="btn btn-outline-dark btn-square"
                                      href
                                      onClick={() =>
                                        handleImageClick(v.projectID)
                                      }
                                    >
                                      <i className="fa fa-search" />
                                    </a>
                                  </div>
                                </div>

                                {/* Product Name start */}
                                {/* <div className="text-center py-4">
                                  <a
                                    className="h6 text-decoration-none text-truncate"
                                    href
                                  >
                                    {v.name}
                                  </a>
                                </div> */}
                                <div
                                  className="text-center py-4 "
                                  style={{ height: "80px" }}
                                >
                                  <a
                                    className="h6 text-decoration-none text-truncate"
                                    style={{
                                      display: "-webkit-box", // Makes the box display behave like a block container for text
                                      WebkitBoxOrient: "vertical", // Sets the orientation to vertical for text
                                      WebkitLineClamp: 2, // Limits the text to two lines
                                      overflow: "hidden", // Hides overflowing content
                                      textOverflow: "ellipsis", // Adds ellipsis when text overflows
                                      wordWrap: "break-word", // Breaks long words onto the next line
                                      whiteSpace: "normal", // Allows wrapping onto the next line
                                      maxWidth: "100%", // Ensures text stays within container width
                                      paddingLeft: "2px",
                                      paddingRight: "2px",
                                    }}
                                    data-toggle="tooltip"
                                    title={v.name}
                                  >
                                    {v.name}
                                  </a>
                                </div>
                                {/* Product Name end */}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {/* Shop Product End */}
              </div>
            </div>
            {/* Shop End */}
          </div>
        )}
      </CookieManager>

      {/* <div className="col-12">
                      <nav>
                        <ul className="pagination justify-content-center">
                          <li className="page-item disabled">
                            <a className="page-link" href="#">
                              Previous
                            </a>
                          </li>
                          <li className="page-item active">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div> */}
      <Pagination
        align="end"
        current={currentPage}
        total={ProjectDetails ? ProjectDetails.length : 0}
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        itemRender={itemRender}
        className="pagenationClass"
        // showTotal={(total, range) =>
        //   `${range[0]}-${range[1]} of ${total} items`
        // }
      />
    </>
  );
}

export default Shop;

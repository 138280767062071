import AxiosServices from "./AxiosServices";
import React from 'react'
const Auth = new AxiosServices();


class NewsLetterServices {
  GetSubcriberList = async () => {
    return Auth.get(`NewsLetter/GetSubcriberList`);
  };
  SaveNewSubcription = async (emailId) => {
    return Auth.post(`NewsLetter/SaveNewSubcription?EmailId=${emailId}`);
  };
  SendNewsLetter = async (formData) => {
    
    return await Auth.post(`NewsLetter/UploadNewsLetter`,formData,{
    "content-type": "multipart/form-data" 
    });  
  }
}

export default NewsLetterServices



import React from "react";

function Preloader() {
  return (
    <>
      <div id="preloader">
        <div id="vaanam-preloader" className="vaanam-preloader">
          <div className="animation-preloader">
            <div className="newloader" />
            <div className="txt-loading">
              <span data-text-preloader="E" className="letters-loading">
                E
              </span>
              <span data-text-preloader="M" className="letters-loading">
                M
              </span>
              <span data-text-preloader="C" className="letters-loading">
                C
              </span>
              <span data-text-preloader="G" className="letters-loading">
                G
              </span>
              <span data-text-preloader="E" className="letters-loading">
                E
              </span>
              <span data-text-preloader="A" className="letters-loading">
                A
              </span>
              <span data-text-preloader="R" className="letters-loading">
                R
              </span>
            </div>
          </div>
          <div className="loader">
            <div className="row">
              <div className="col-3 loader-section section-left">
                <div className="bg" />
              </div>
              <div className="col-3 loader-section section-left">
                <div className="bg" />
              </div>
              <div className="col-3 loader-section section-right">
                <div className="bg" />
              </div>
              <div className="col-3 loader-section section-right">
                <div className="bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Preloader;

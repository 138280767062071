import React, { memo, useEffect, useState } from "react";
import CommonServices from "../../../services/CommonServices";
import { useNavigate } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/OwlCarousel";

function Categories() {
  var CommonServicesObj = new CommonServices();
  const [Category, setCategory] = useState([]);

  useEffect(() => {
    CommonServicesObj.GetCategoryList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setCategory([...res.data]);
      }
    });
  }, []);

  const navigate = useNavigate();
  const handleCategoryNavigate = (id) => {
    navigate("/shop", {
      state: { categoryID: id },
      replace: true,
    });
  };

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 1500,
    smartSpeed: 1000,
    responsive: {
      0: { items: 2 },
      576: { items: 3 },
      768: { items: 4 },
      992: { items: 5 },
      1200: { items: 6 },
    },
  };
  return (
    <>
      {/* Categories Start */}
      <div className="container-fluid pt-5">
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4">
          <span className="bg-secondary pr-3">Categories</span>
        </h2>

        <div className="row px-xl-5">
          <div className="col">
            <OwlCarousel
              options={options}
              className="related-carousel owl-carousel"
            >
              {Category.map((category) => (
                <div
                  key={category.categoryID}
                  className="product-item bg-light"
                  style={{
                    width: "180px",
                    height: "280px",
                  }}
                >
                  <div className="item bg-light p-2">
                    <img
                      className="img-fluid "
                      style={{ height: "130px" }}
                      src={`${
                        process.env.REACT_APP_API_IMAGEPATH +
                        category.categoryImagePath
                      }`}
                      alt=""
                    />
                    {/* <div className="product-action">
                    <a className="btn btn-outline-dark btn-square" href>
                      <i className="fa fa-shopping-cart" />
                    </a>
                    <a className="btn btn-outline-dark btn-square" href>
                      <i className="fa fa-search" />
                    </a>
                  </div> */}
                  </div>
                  <div className="text-center py-4">
                    <a
                      className="h6 text-decoration-none text-truncate product-name"
                      href
                      style={{ height: "50px" }}
                    >
                      <h6
                        className="mt-2"
                        style={{
                          display: "-webkit-box", // Makes the box display behave like a block container for text
                          WebkitBoxOrient: "vertical", // Sets the orientation to vertical for text
                          WebkitLineClamp: 2, // Limits the text to two lines
                          overflow: "hidden", // Hides overflowing content
                          textOverflow: "ellipsis", // Adds ellipsis when text overflows
                          wordWrap: "break-word", // Breaks long words onto the next line
                          whiteSpace: "normal", // Allows wrapping onto the next line
                          maxWidth: "100%", // Ensures text stays within container width
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                        data-toggle="tooltip"
                        title={category.categoryName}
                      >
                        {category.categoryName}
                      </h6>
                    </a>
                    <a
                      href
                      className="btn btn-primary mt-1"
                      onClick={() =>
                        handleCategoryNavigate(category.categoryID)
                      }
                    >
                      View Equipments
                    </a>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* Categories End */}
    </>
  );
}

export default memo(Categories);

{
  /* <div className=" px-xl-5 pb-3 ">
          <div className=" d-flex flex-wrap justify-content-between bg-secondary mb-3">
            {Category.map((category) => (
              
              <div
                className="card m-2"
                key={category.categoryID}
                style={{ width: "200px", height:"300px" }}
              >
                <div className="" key={category.categoryID}>
                  <img
                    src={`${
                      process.env.REACT_APP_API_IMAGEPATH +
                      category.categoryImagePath
                    }`}
                    height={190}
                    width={200}
                    
                    alt={category.categoryName}
                  />
                </div>
                <div className="card-body">
                  <div>
                    <h6 className="mt-2">{category.categoryName}</h6>
                  </div>
                  <a href className="btn btn-primary mt-2"
                  onClick={() => handleCategoryNavigate(category.categoryID)}
                  >
                   View Equipments
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div> */
}

import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class ProjectServices {
  Create = async (data) => {
    return Auth.post(`Project/Create`, data);
  };
  UploadFiles = async (uploadfiles) => {
    return Auth.post(`Project/FilesUpload`, uploadfiles);
  };

  ProcessCart = async (data) => {
    // 
    return Auth.post(`Project/ProcessCart`, data);
  };

  GetProjectList = async () => {
    // 
    return Auth.get(`Project/GetProjectList`);
  };

  GetProductListByProductID = async (id) => {
    return Auth.get(`Project/GetProductListByProductID?id=${id}`);
  };

  Client_GetProjectList = async () => {
    return Auth.get(`Project/Client_GetProjectList`);
  };

  // GetProjectClientImg = async (id) => {
  //   return Auth.get(`Project/GetProjectClintImg=${id}`);
  // };

  DeleteProjectImage = async (id) => {
    return Auth.get(`Project/DeleteProjectImage?id=${id}`);
  };
  MulitpleUploadFiles = async (uploadfiles) => {
    return Auth.post(`Project/UploadFileMultiple`, uploadfiles);
  };
  DeleteProject = async (id) => {
    return Auth.get(`Project/DeleteProject?id=${id}`);
  };
}

export default ProjectServices;

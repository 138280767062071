import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import NewsLetterServices from "../../../services/NewsLetterServices";

var NewsLetterServicesObj = new NewsLetterServices();

function Footer() {
  //console.log("Footer");

  const [emailId, setEmailId] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailId("");
    try {
      const response = await NewsLetterServicesObj.SaveNewSubcription(emailId);

      if (response.status === 200) {
        Swal.fire({
          title: "Thanks for subscribing!",
          text: "You have successfully subscribed with your email address.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setEmailId("");
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to save subscription.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {}
  };

  return (
    <>
      {/* Footer Start */}
      <div className="container-fluid bg-dark text-secondary mt-5 pt-2">
        <div className="row px-xl-5 pt-1">
          <div className=" col-md-12">
            <div className="row">
              <div className=" col-11 col-md-4 mb-3 ml-auto ">
                <h5 className="text-secondary text-uppercase mb-1">
                  Newsletter
                </h5>
                <p className="mb-2">
                  Please subscribe our Newsletter for new updates{" "}
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email Address"
                      onChange={(e) => setEmailId(e.target.value)}
                      value={emailId}
                      required
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please enter a valid email address."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")} // Clear message on user input
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        Sign Up
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row border-top mx-xl-5 py-4"
          style={{ borderColor: "rgba(256, 256, 256, .1) !important" }}
        >
          <div
            className=" mt-3"
            style={{ width: "100%" }}
            //style={{ width: "-webkit-fill-available", width: "-moz-available" }}
          >
            <p className="mb-md-0 text-center  text-secondary">
              © <a className="text-primary" href="#"></a> Copyright{" "}
              {new Date().getFullYear()} All Rights Reserved by
              <a
                className="text-primary"
                href="https://emcgear.in/"
                style={{ paddingLeft: "5px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                EMC Gear.
              </a>{" "}
              Powered by{" "}
              <a
                className="text-primary"
                href="https://vaanamtech.com/"
                style={{ paddingLeft: "5px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Vaanam Technologies Pvt Ltd.
              </a>
            </p>
          </div>

          {/* <div className="col-md-6 px-xl-0 text-center text-md-right">
            <img className="img-fluid" src="img/payments.png" alt="" />
          </div> */}
        </div>
      </div>
      {/* Footer End */}
    </>
  );
}

export default memo(Footer);

export const BackToTop = memo(({ onClick, children }) => {
  return (
    <a href="#" className="btn btn-primary back-to-top" onClick={onClick}>
      <i className="fa fa-angle-double-up"></i>
      {children}
    </a>
  );
});

{
  /* <div className="row px-xl-5 pt-5" style={{ display: "none" }}>
  <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
    <h5 className="text-secondary text-uppercase mb-4">Get In Touch</h5>
    <p className="mb-4">
      No dolore ipsum accusam no lorem. Invidunt sed clita kasd clita et et
      dolor sed dolor. Rebum tempor no vero est magna amet no
    </p>
    <p className="mb-2">
      <i className="fa fa-map-marker-alt text-primary mr-3" />
      123 Street, New York, USA
    </p>
    <p className="mb-2">
      <i className="fa fa-envelope text-primary mr-3" />
      info@example.com
    </p>
    <p className="mb-0">
      <i className="fa fa-phone-alt text-primary mr-3" />
      +012 345 67890
    </p>
  </div>
  <div className="col-lg-8 col-md-12">
    <div className="row">
      <div className="col-md-4 mb-5">
        <h5 className="text-secondary text-uppercase mb-4">Quick Shop</h5>
        <div className="d-flex flex-column justify-content-start listAlignments">
         
          <Link to="/" className="text-secondary mb-2">
            <i className="fa fa-angle-right mr-2" />
            Home
          </Link>
          <Link to={"/shop"} className="text-secondary mb-2">
            <i className="fa fa-angle-right mr-2" />
            Products
          </Link>
          <Link to={"/cart"} className="text-secondary mb-2">
            <i className="fa fa-angle-right mr-2" />
            Shopping Cart
          </Link>
          <Link to={"/contact"} className="text-secondary">
            <i className="fa fa-angle-right mr-2" />
            Contact Us
          </Link>
        </div>
      </div>
      <div className="col-md-4 mb-5">
        <h5 className="text-secondary text-uppercase mb-4">My Account</h5>
        <div className="d-flex flex-column justify-content-start">
         
          <Link to="/" className="text-secondary mb-2">
            <i className="fa fa-angle-right mr-2" />
            Home
          </Link>
          <Link to={"/shop"} className="text-secondary mb-2">
            <i className="fa fa-angle-right mr-2" />
            Products
          </Link>
          <Link to={"/cart"} className="text-secondary mb-2">
            <i className="fa fa-angle-right mr-2" />
            Shopping Cart
          </Link>
          <Link to={"/contact"} className="text-secondary">
            <i className="fa fa-angle-right mr-2" />
            Contact Us
          </Link>
        </div>
      </div>
      <div className=" col-11 col-md-4 mb-5">
        <h5 className="text-secondary text-uppercase mb-4">Newsletter</h5>
        <p>Duo stet tempor ipsum sit amet magna ipsum tempor est</p>
        <form
          // action
          onSubmit={handleSubmit}
        >
          <div className="input-group">
            <input
              type="email"
              className="form-control"
              placeholder="Your Email Address"
              onChange={(e) => setEmailId(e.target.value)}
              value={emailId}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={() => {}}>
                Sign Up
              </button>
            </div>
          </div>
        </form>
        <h6 className="text-secondary text-uppercase mt-4 mb-3">Follow Us</h6>
        <div className="d-flex">
          <a className="btn btn-primary btn-square mr-2" href="#">
            <i className="fab fa-twitter" />
          </a>
          <a className="btn btn-primary btn-square mr-2" href="#">
            <i className="fab fa-facebook-f" />
          </a>
          <a className="btn btn-primary btn-square mr-2" href="#">
            <i className="fab fa-linkedin-in" />
          </a>
          <a className="btn btn-primary btn-square" href="#">
            <i className="fab fa-instagram" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>; */
}

import React, { useState, useReducer, useEffect } from "react";
import "jquery";
import $ from "jquery";
// import "bootstrap-4-react";
import AddOrEditBanner from "./AddOrEditBanner";
import BannerServices from "../../../services/BannerServices";
import HeaderDashboard from "../DashBoard/HeaderDashboard";
import Swal from "sweetalert2";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import Preloader from "../../Preloader";
import ScrollToTop from "react-scroll-to-top";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";
var BannerServicesObj = new BannerServices();
const HomeBanner = () => {
  const [Model, setModel] = useState({
    sliderID: "",
    title: "",
    description: "",
    imageUrl: "",
    sliderUrl: "",
    sliderOrder: "",
  });
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [SliderDetails, setSliderDetails] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [showComponent, setShowComponent] = useState(false); // State to manage delay

  useEffect(() => {
    import("../Css/styleVt.css");
    import("../Css/bootsnav.css");

    // Delay for 1 second before showing the component
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 200);
  });

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    BannerServicesObj.GetSliderList().then((res) => {
      try {
        if (res.data != null && res.data.length > 0) {
          setSliderDetails([...res.data]);
        }
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      } catch (res) {
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      }
    });
  }, [Render]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function GetEdit(
    sliderID,
    title,
    description,
    imageUrl,
    sliderUrl,
    sliderOrder
  ) {
    //
    setModel({
      sliderID: sliderID,
      title: title,
      description: description,
      imageUrl: imageUrl,
      sliderUrl: sliderUrl,
      sliderOrder: sliderOrder,
    });
  }

  function DeleteSlider(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Slider?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        BannerServicesObj.Delete(id).then((res) => {
          if (res.data == 1) {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Delete Failed!",
            });
          }
        });
      }
    });
  }

  if (!showComponent) {
    return null; // Or return a loading spinner, etc.
  }

  return Auth ? (
    <>
      <Preloader />

      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card grid">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">Banners </h4>
                      <a
                        href="#"
                        className="btn btn-primary btn-round ml-auto"
                        data-toggle="modal"
                        data-target="#addRowModal"
                      >
                        <i className="fa fa-plus"></i>
                        Add Banner
                      </a>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table
                        className="display table table-striped table-hover LoginCenter"
                        id="AssociateDisplay"
                      >
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Banner Image</th>
                            <th>Banner Redirect Url</th>
                            <th style={{ textAlign: "center" }}>Action(s)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {SliderDetails != null && SliderDetails.length > 0 ? (
                            SliderDetails.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>{v.title}</td>
                                  <td>{v.description}</td>
                                  <td>
                                    <img
                                      className="tableimage"
                                      src={
                                        process.env.REACT_APP_API_IMAGEPATH +
                                        v.imageUrl
                                      }
                                      alt="No Image"
                                    />
                                  </td>
                                  <td>{v.sliderUrl}</td>

                                  <td>
                                    <div className="form-button-action tableoptions">
                                      <button
                                        type="button"
                                        title=""
                                        className="btn btn-link  btn-lg"
                                        data-original-title="Edit"
                                        data-toggle="modal"
                                        data-target="#addRowModal"
                                        onClick={() =>
                                          GetEdit(
                                            v.sliderID,
                                            v.title,
                                            v.description,
                                            v.imageUrl,
                                            v.sliderUrl,
                                            v.sliderOrder
                                          )
                                        }
                                      >
                                        {/* <FaPencilAlt className="bg-primary"/> */}
                                        <FaPencilAlt
                                          style={{ color: "#3B71CA" }}
                                        />
                                      </button>

                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn btn-link "
                                        data-original-title="Remove"
                                        onClick={() => DeleteSlider(v.sliderID)}
                                      >
                                        <FaTrashAlt
                                          style={{ color: "#DC4C64" }}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <label>No Data in Table</label>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-backdrop="static"
        >
          <AddOrEditBanner Model={Model} />
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a
                      className="text-primary"
                      href="https://vaanamtech.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Vaanam Technologies Pvt Ltd.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};
export default HomeBanner;

import React, { useState, useEffect } from "react";
import ProjectServices from "../../../services/ProjectServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { FaPencilAlt, FaTrashAlt, FaCloudDownloadAlt } from "react-icons/fa";
import ProductCategoryServices from "../../../services/ProductCategoryServices";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";

var ProjectCategoryServicesObj = new ProductCategoryServices();
var ProjectServicesObj = new ProjectServices();

const AddOrEditProjects = (props) => {
  console.log(props.Model);
  const [image, setImage] = useState([]);
  const [logo, setlogo] = useState();
  const [profilePic, setProfilePic] = useState();
  const [clientlogo, setclientlogo] = useState();
  const [ProjectDetails, setProjectDetails] = useState([]);
  const [Category, setCategory] = useState([]);
  const [selectedCategoryID, setSelectedCategoryID] = useState(0);
  const [error, setformError] = useState({
    project_name: "",
    project_description: "",
    project_image: "",
    project_logo: "",
    product_profile_pic: "",
    Client_logo: "",
    category_change: "",
    Youtube_link: " ",
  });
  const [featuredProduct, setFeaturedProduct] = useState(true);
  const cookies = new Cookies();
  const [fileList, setFileList] = useState([]);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    ProjectCategoryServicesObj.GetProjectList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setCategory([...res.data]);
        setSelectedCategoryID(props.Model.categoryID);
        setFeaturedProduct(props.Model.featuredProducts);
      }
    });
    // setTimeout(() => {
    //   $("#preloader").hide();
    // }, 1000);
    // });
  }, [props.Model]);

  function DeleteClientLogo(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Project?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ProjectServicesObj.DeleteProjectImage(id).then((res) => {
          if (res.data == 1) {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Delete Failed!",
            });
          }
        });
      }
    });
  }

  const CloseModel = () => {
    document.getElementById("AddOrEditProjects").reset();
    props.Model.projectID = "";
    props.Model.name = "";
    props.Model.description = "";
    props.Model.categoryID = "";
    props.Model.categoryName = "";
    setSelectedCategoryID("");
    props.Model.imageUrl = "";
    props.Model.imageUrls = "";
    props.Model.logoUrl = "";
    props.Model.projectOrder = "";
    props.Model.websiteUrl = "";
    setFeaturedProduct(true);
    const feedback = document.getElementById("feedback");
    feedback.textContent = "";
    props.Model.profilePic = "";

    setformError({
      project_name: "",
      project_description: "",
      project_image: "",
      project_logo: "",
      category_change: "",
      Youtube_link: " ",
      profile_image: " ",
    });
  };

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const namechange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.project_name = "Product Name is Required";
    } else {
      myerror.project_name = "";
    }
    setformError({ ...myerror });
  };

  const categorychange = (e) => {
    setSelectedCategoryID(e.target.value);
    var myerror = error;
    if (e.target.value == null || e.target.value == "" || e.target.value == 0) {
      myerror.category_change = "Select Category";
    } else {
      myerror.category_change = "";
    }
    setformError({ ...myerror });
    // setSelectedCategoryID(e.target.value);
    // props.Model.categoryID=e.target.value;
  };

  const descriptionchange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.project_description = "Description is Required";
    } else {
      myerror.project_description = "";
    }
    setformError({ ...myerror });
  };

  const imagechange = (e) => {
    let myerror = { ...error }; // Copy error object to update the state

    const files = Array.from(e.target.files); // Convert FileList to array
    const existingImages = props.Model.imageUrls ? props.Model.imageUrls : []; // Ensure imageUrls is an array

    const maxFiles = 2;
    const totalImages = files.length + existingImages.length; // Calculate total images (newly selected + existing)

    if (props.Model.projectID == null || props.Model.projectID === "") {
      if (files.length === 0) {
        myerror.project_image = "Product Image is Required"; // No files selected
        setImage([]); // Clear image state
        //setImage(files);
      } else if (totalImages > maxFiles) {
        myerror.project_image = `Only ${maxFiles} images can be uploaded.`; // Limit exceeded
        e.target.value = null; // Clear the input field
      } else {
        setImage(e.target.files); // Store the selected files in state
        myerror.project_image = ""; // No error
      }
    } else {
      if (totalImages > maxFiles) {
        myerror.project_image = `Only ${maxFiles} images can be uploaded.`; // Limit exceeded
        e.target.value = null; // Clear the input field
      }
    }
    setformError(myerror); // Update error state
  };

  const logochange = (e) => {
    if (props.Model.projectID == null || props.Model.projectID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.project_logo = "Product Logo is Required";
        setlogo("");
      } else {
        setlogo(e.target.files[0]);
        myerror.project_logo = "";
      }
      setformError({ ...myerror });
    }
  };

  const profilePicChange = (e) => {
    // const files = e.target.files;
    // const maxFiles = 2;

    // if (files.length > maxFiles) {
    //   //alert(`You can only upload a maximum of ${maxFiles} images.`);
    //   // Clear the input if the limit is exceeded
    //   e.target.value = null;
    //   myerror.profile_image = "Only 2 images can be upload";
    //   return;
    // }

    if (props.Model.projectID == null || props.Model.projectID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.profile_image = "Product Profile Image is Required";
        setProfilePic("");
      } else {
        setProfilePic(e.target.files[0]);
        myerror.profile_image = "";
      }
      setformError({ ...myerror });
    }
  };

  const Clientlogochange = (e) => {
    if (props.Model.projectID == null || props.Model.projectID == "") {
      var myerror = error;
      if (e.target.value == null || e.target.value == "") {
        myerror.Client_logo = "Client Logo is Required";
        setclientlogo("");
      } else {
        setclientlogo([...e.target.files]);
        myerror.Client_logo = "";
      }
      setformError({ ...myerror });
    }
  };

  var uploadfiles = { image: image, logo: logo, profilePic: profilePic };

  const validateURL = (e) => {
    var myerror = error;

    const urlInput = document.getElementById("websiteUrl").value;
    const feedback = document.getElementById("feedback");

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    // fragment locator

    if (urlPattern.test(urlInput)) {
      myerror.Youtube_link = "";
      feedback.textContent = "Valid URL";
      feedback.style.color = "green";
    } else {
      myerror.Youtube_link = "";
      feedback.textContent = "Invalid URL";
      feedback.style.color = "red";
    }
    setformError({ ...myerror });
  };

  const youtubeLinkChange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      const feedback = document.getElementById("feedback");
      feedback.textContent = "";
      myerror.Youtube_link = "Link is Required";
    } else {
      myerror.Youtube_link = "";
    }
    setformError({ ...myerror });
  };

  const handleCheckboxChange = (e) => {
    setFeaturedProduct(e.target.checked);
  };

  const onSubmit = (res) => {
    res.preventDefault();

    var myerror = error;
    var flag = true;

    var data = {
      SavedBY: parseInt(cookies.get("userID")),
      ProjectID: parseInt(props.Model.projectID),
      Name: document.getElementById("name").value,
      Description: document.getElementById("description").value,
      CategoryID: selectedCategoryID,
      ProfilePic: null,
      ImageUrl: null,
      ImageUrls: null,
      WebsiteUrl: document.getElementById("websiteUrl").value,
      FeaturedProducts: featuredProduct ? true : false,
      // LogoUrl: null,
      // ProjectOrder: parseInt(document.getElementById("orderby").value),
      // clientlogoUrls: null,
    };

    if (data.Name == null || data.Name == "") {
      myerror.project_name = "Product Name is Required";

      flag = false;
    } else {
      myerror.project_name = "";
    }

    if (
      data.CategoryID == null ||
      data.CategoryID == "" ||
      data.categoryID == 0
    ) {
      myerror.category_change = "Select category";
      flag = false;
    } else {
      myerror.category_change = "";
    }

    if (data.Description == null || data.Description == "") {
      myerror.project_description = "Description is Required";
      flag = false;
    } else {
      myerror.project_description = "";
    }

    if (data.WebsiteUrl == null || data.WebsiteUrl == "") {
      myerror.Youtube_link = "Link is Required";
      flag = false;
    } else {
      myerror.Youtube_link = "";
    }

    if (props.Model.projectID == null || props.Model.projectID == "") {
      if (uploadfiles.image == null || uploadfiles.image == "") {
        myerror.project_image = "Product Image is Required";
        flag = false;
      } else {
        myerror.project_image = "";
        myerror.project_logo = "";
      }
      // if (uploadfiles.logo == null || uploadfiles.logo == "") {
      //   myerror.project_logo = " Client Logo is Required";
      //   flag = false;
      // } else {
      //   myerror.project_logo = "";
      // }
    }

    if (props.Model.projectID == null || props.Model.projectID == "") {
      if (uploadfiles.profilePic == null || uploadfiles.profilePic == "") {
        myerror.product_profile_pic = "Product Profile Image is Required";
        flag = false;
      } else {
        myerror.product_profile_pic = "";
        // myerror.project_logo = "";
      }
    }

    setformError({ ...myerror });
    if (flag == true) {
      const formData = new FormData();
      const clientformData = new FormData();
      // $.each($("#logo")[0].files, function (i, file) {
      //   formData.append("Logo", file);
      // });

      $.each($("#image")[0].files, function (i, file) {
        formData.append("Image", file);
      });

      // $.each($("#Clientlogo")[0].files, function (i, file) {
      //   formData.append("clientlogo", file);
      // });

      $.each($("#profilePic")[0].files, function (i, file) {
        formData.append("ProfilePic", file);
      });

      ProjectServicesObj.UploadFiles(formData).then((res) => {
        //data.ImageUrl = res.data.imageUrl;

        // console.dir(res);
        data.ProfilePic = res.data.profilePic;
        data.ImageUrls = res.data.imageUrls;
        // data.LogoUrl = res.data.logoUrl;
        // data.clientlogoUrls = res.data.clientlogoUrls;

        // if(clientlogo != null && clientlogo != ""){
        //   clientformData.append("clientlogo", clientlogo);
        // }
        // data.Clientlogo = clientformData;

        ProjectServicesObj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");
            Toast.fire({
              icon: "success",
              title: "Save Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Save!",
            });
          }
        });
      });
    }
  };

  function extractImageName(url) {
    // Get the last part of the URL after the last "/"
    const imageNameWithGuid = url.substring(url.lastIndexOf("/") + 1);

    // Remove the GUID part by splitting at the underscore
    const imageNameParts = imageNameWithGuid.split("_");

    // Join the parts of the image name, excluding the GUID part
    const imageName = imageNameParts.slice(1).join("_");

    // Remove any query parameters or hashes from the image name
    const cleanImageName = imageName.split("?")[0].split("#")[0];

    return cleanImageName;
  }

  const onChange = ({ fileList: newFileList }) => {
    console.log(fileList, "fileList");
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadMulitpleImage = async () => {
    let list = [];
    list = fileList.map((e) => {
      return e.originFileObj;
    });
    console.log(list);
    const formData = new FormData();

    formData.append("ImageList", fileList);

    await ProjectServicesObj.MulitpleUploadFiles(formData)
      .then((res) => {})
      .catch((err) => {
        console.log(err, "upload error");
      });
  };
  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Add Product</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={CloseModel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form onSubmit={onSubmit} id="AddOrEditProjects" autocomplete="off">
              <div className="row">
                {/* Product name start */}
                <div className="col-sm-4">
                  <div className="form-group text-left ">
                    <label>
                      Product Name <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      maxLength={200}
                      defaultValue={props.Model.name}
                      placeholder="Product Name"
                      name="name"
                      onChange={namechange}
                    />

                    <span className="text-danger">{error.project_name}</span>
                  </div>
                </div>
                {/* Product name end */}

                {/* Product category start */}
                <div className="col-sm-4">
                  <div className="form-group text-left ">
                    <label>
                      Category
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="categoryID"
                      name="categoryID"
                      className="form-control"
                      //value={props.Model.categoryID}
                      value={selectedCategoryID}
                      //onChange={(e) => setSelectedCategoryID(e.target.value)}
                      onChange={categorychange}
                      //required
                    >
                      <option value="">Select a category</option>
                      {Category.map((category) => (
                        <option
                          key={category.categoryID}
                          value={category.categoryID}
                        >
                          {category.categoryName}
                        </option>
                      ))}
                    </select>
                    <span className="text-danger">{error.category_change}</span>
                  </div>
                </div>
                {/* Product category end */}

                {/* Product description start */}
                <div className="col-md-4">
                  <div className="form-group  text-left ">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>

                    <textarea
                      id="description"
                      className="form-control"
                      maxLength={1500}
                      defaultValue={props.Model.description}
                      placeholder="Product Description"
                      name="description"
                      onChange={descriptionchange}
                    />

                    <span className="text-danger">
                      {error.project_description}
                    </span>
                  </div>
                </div>
                {/* Product description end */}

                {/* Profile Image start */}
                <div className="col-sm-4">
                  <div className="form-group text-left ">
                    <label>
                      Product Profile Image
                      {props.Model.projectID == null ||
                      props.Model.projectID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="profilePic"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.profilePic}
                      name="profilePic"
                      onChange={profilePicChange}
                      accept=".png, .jpg, .jpeg"
                    />
                    <span className="text-danger">
                      {error.product_profile_pic}
                    </span>
                    {props.Model.profilePic ? (
                      <>
                        <span>{extractImageName(props.Model.profilePic)}</span>
                        &nbsp;
                        <a
                          href={
                            process.env.REACT_APP_API_IMAGEPATH +
                            props.Model.profilePic
                          }
                          target="_blank"
                        >
                          <FaCloudDownloadAlt />
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />

                    {/* <span className="text-danger">{error.profile_pic}</span> */}
                  </div>
                </div>
                {/* Profile Image end */}

                {/* Product link start */}
                <div className="col-sm-4">
                  <div className="form-group text-left ">
                    <label>
                      Youtube Link
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="websiteUrl"
                      placeholder="Enter Youtube Link"
                      name="websiteUrl"
                      defaultValue={props.Model.websiteUrl}
                      onInput={validateURL}
                      onChange={youtubeLinkChange}
                    />
                    <span className="text-danger">{error.Youtube_link}</span>
                    <span id="feedback"></span>
                  </div>
                </div>
                {/* Product link end */}

                {/* Freatured products start */}
                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Featured Product
                      <input
                        id="featuredProduct"
                        name="featuredProduct"
                        type="checkbox"
                        className="form-control"
                        checked={featuredProduct}
                        onChange={handleCheckboxChange}
                      />{" "}
                      {/* <span className="text-danger">{error.Client_logo}</span> */}
                    </label>
                  </div>
                </div>
                {/* Freatured products end */}

                {/* Product images start */}
                <div className="col-sm-4">
                  <div className="form-group text-left ">
                    <label>
                      Product Image (Max 2)
                      {props.Model.projectID == null ||
                      props.Model.projectID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>

                    <input
                      id="image"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.imageUrls}
                      name="image"
                      onChange={imagechange}
                      accept=".png, .jpg, .jpeg"
                      multiple={true}
                      //disabled={totalImageCount >= 2} // Disable if total images exceed 2
                    />
                    <span className="text-danger">{error.project_image}</span>
                    {/* <ImgCrop rotationSlider>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        beforeUpload={() => false}>
                        {fileList.length < 2 && "+ Upload"}
                      </Upload>
                    </ImgCrop> */}

                    {/* <button
                      className="btn btn-primary"
                      onClick={uploadMulitpleImage}>
                      Upload
                    </button> */}

                    {props.Model.imageUrl ? (
                      <>
                        <span>{extractImageName(props.Model.imageUrl)}</span>
                        &nbsp;
                        <a
                          href={
                            process.env.REACT_APP_API_IMAGEPATH +
                            props.Model.imageUrl
                          }
                          target="_blank"
                        >
                          <FaCloudDownloadAlt />
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                    <br />
                  </div>
                </div>
                {/* Product images end */}

                {/* <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Client Logo
                      {props.Model.projectID == null ||
                      props.Model.projectID == "" ? (
                        <span className="text-danger">*</span>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      id="Clientlogo"
                      type="file"
                      className="form-control"
                      defaultValue={props.Model.logoUrl}
                      name="Clientlogo"
                      onChange={Clientlogochange}
                      accept=".png, .jpg, .jpeg"
                      multiple={true}
                    />
                    <span className="text-danger">{error.Client_logo}</span>
                  </div>
                </div> */}

                {/* <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Product Order</label>

                    <input
                      type="text"
                      id="orderby"
                      className="form-control"
                      maxLength={3}
                      defaultValue={props.Model.projectOrder}
                      placeholder="Product Order"
                      name="orderby"
                      onKeyDown={(event) => onKeyDown(event)}
                      onChange={(e) => numberOnly("orderby")}
                    />
                  </div>
                </div>  */}

                {/* <div class="card-body">
                  <div class="table-responsive">
                    <table
                      className="display table table-striped table-hover"
                      id="AssociateDisplay"
                    >
                      <thead>
                        <tr>
                          <th>Client Logo</th>

                          <th>Action(s)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {props.Model.clientlogoUrls != null &&
                        props.Model.clientlogoUrls.length > 0 ? (
                          props.Model.clientlogoUrls.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <img
                                    className="tableimage"
                                    src={
                                      process.env.REACT_APP_API_IMAGEPATH +
                                      v.clientlogoUrl
                                    }
                                    alt="No Logo"
                                  />
                                </td>

                                <td>
                                  <div class="form-button-action tableoptions">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      class="btn btn-link "
                                      data-original-title="Remove"
                                      onClick={() =>
                                        DeleteClientLogo(v.projectClientId)
                                      }
                                    >
                                      <FaTrashAlt />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <label>No Data in Table</label>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>

              {/*selected Images in grid view start */}
              <div class="card-body">
                <div class="table-responsive">
                  <table
                    className="display table table-striped table-hover"
                    id="AssociateDisplay"
                  >
                    <thead>
                      <tr>
                        <th>Product Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {props.Model.imageUrls != null &&
                      props.Model.imageUrls.length > 0 ? (
                        props.Model.imageUrls.map((v, i) => {
                          //
                          return (
                            <tr key={i}>
                              <td>
                                <img
                                  className="tableimage"
                                  src={
                                    process.env.REACT_APP_API_IMAGEPATH +
                                    v.imageUrl
                                  }
                                  alt="No Logo"
                                />
                              </td>

                              <td>
                                <div
                                  className="form-button-action tableoptions"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-link "
                                    data-original-title="Remove"
                                    onClick={() => DeleteClientLogo(v.proImgId)}
                                  >
                                    <FaTrashAlt style={{ color: "#DC4C64" }} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <label>No Data in Table</label>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*selected Images in grid view end */}

              <div className="modal-footer no-bd">
                <button
                  type="submit"
                  id="addRowButton"
                  className="btn btn-primary"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={CloseModel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddOrEditProjects;

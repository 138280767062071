import React, { useState, useEffect } from "react";
import jQuery from "jquery";
import $ from "jquery";
// import AddOrEditProjects from "./AddOrEditProductCategory";
// import ProductCategoryServices from "../../../services/ProductCategoryServices";
import HeaderDashboard from "../DashBoard/HeaderDashboard";
import Swal from "sweetalert2";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import Preloader from "../../Preloader";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";
import NewsLetterServices from "../../../services/NewsLetterServices";

var NewsLetterServicesObj = new NewsLetterServices();

const SubscribersListTable = () => {
  const [Model, setModel] = useState({
    CategoryID: "",
    CategoryName: "",
  });
  const [SubscriberDetails, setSubscriberDetails] = useState([]);
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [mailRefresh, setMailRefresh] = useState("");

  const [showComponent, setShowComponent] = useState(false); // State to manage delay

  useEffect(() => {
    import("../Css/styleVt.css");
    import("../Css/bootsnav.css");

    // Delay for 1 second before showing the component
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 100);
  });

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    NewsLetterServicesObj.GetSubcriberList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setSubscriberDetails([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  const newsLetterUpload = async (e) => {
    setMailRefresh("");
    const checkPDF = e.target.files[0];

    if (checkPDF) {
      const fileName = checkPDF.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension !== "pdf") {
        Swal.fire({
          icon: "warning",
          title: "Please upload a PDF formated file.",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "custom-swal-container",
          },
        });
        //  document.getElementById('resumePath').innerText = 'Please upload a PDF formated file.';
        e.target.value = "";
      } else {
        // element.style.border = '';

        const formData = new FormData();
        $.each($("#newsLetterPath")[0].files, function (i, file) {
          formData.append("NewsLetter", file);
        });
        const response = await NewsLetterServicesObj.SendNewsLetter(
          formData
        ).then((res) => {
          return res.data;
        });
        console.log("response", response);

        if (response) {
          swal({
            title: "Mail send",
            text: "Monthly news letter send",
            icon: "success",
            // button: "Aww yiss!",
            timer: 2500,
            buttons: false,
          });
        }
        document.getElementById("newsLetterPath").value = "";

        //NewsLetterServicesObj
        // await RecruitmentServicesObj.UploadResume(formData).then(async(res) => {
        //

        document.getElementById("newsLetterPath").style.border = "";
      }
    }
  };

  if (!showComponent) {
    return null; // Or return a loading spinner, etc.
  }

  return Auth ? (
    <>
      <Preloader />
      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card grid">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">News letter subscribers </h4>
                      {/* <a
                        href="#"
                        className="btn btn-primary btn-round "
                        // data-toggle="modal"
                         data-target="#addRowModal"

                      ></a> */}
                      <button
                        className="ml-auto btn btn-danger"
                        onClick={() =>
                          document.getElementById("newsLetterPath").click()
                        }
                      >
                        Send News letter
                        <i
                          className="fa fa-paperclip"
                          style={{ marginLeft: "8px" }}
                        >
                          <input
                            type="file"
                            name="newsLetterPath"
                            accept=".pdf"
                            className="form-control"
                            id="newsLetterPath"
                            onChange={newsLetterUpload}
                            style={{ display: "none" }}
                            //   value={formData.companyName}
                          />
                        </i>
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="table-responsive">
                      <table
                        className="display table table-striped table-hover LoginCenter"
                        id="AssociateDisplay"
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Subscriber Email ID</th>
                          </tr>
                        </thead>

                        <tbody>
                          {" "}
                          {SubscriberDetails != null &&
                          SubscriberDetails.length > 0 ? (
                            SubscriberDetails.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{v.emailId}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <label>No Data in Table</label>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-backdrop="static"
        ></div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a
                      className="text-primary"
                      href="https://vaanamtech.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Vaanam Technologies Pvt Ltd.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};

export default SubscribersListTable;

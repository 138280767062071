import React from "react";
import AxiosServices from "./AxiosServices";
const Auth = new AxiosServices();

class CommonServices {
    // Create = async (data) => {
    //     return Auth.post('/Career/SaveCareerDetail', data);
    // };
    // Delete = async (id) => {
    //     return Auth.get(`/Career/DeleteCareer?id=${id}`);
    //   };
    // GetDropdown = async () => {
    //     return Auth.get('/Career/GetDropDown');
    //   };
    // GetCareerAdmin = async () => {
    //     return Auth.get('/Career/Career_GetCareerList');
    //   };
      
      GetCategoryList = async () => {
        return Auth.get(`Project/GetCategoryList`);
      };

      GetFeaturedProducts = async () => {
        return Auth.get(`Project/GetFeaturedProducts`);
      };

      GetRecentProducts = async () => {
        return Auth.get(`Project/GetRecentProducts`);
      };

      GetProductsByCategories = async (id) => {
        
        return Auth.get(`Project/GetProductsByCategories?Id=${id}`);
      };
}

export default CommonServices;

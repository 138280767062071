import React, { useState, useEffect } from "react";
import jQuery from "jquery";
import $ from "jquery";
import HeaderDashboard from "../DashBoard/HeaderDashboard";
import Swal from "sweetalert2";
import {
  FaCartPlus,
  FaEye,
  FaFolderPlus,
  FaPencilAlt,
  FaPlusCircle,
  FaPlusSquare,
  FaTrashAlt,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import Preloader from "../../Preloader";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";
import ProjectEnquirerServices from "./ProjectEnquirerServices";
// import "../Css/styleVt.css";
import { Space, Switch, Pagination } from "antd";

var ProjectServicesObj = new ProjectEnquirerServices();

const ProductEnquirer = () => {
  const [Model, setModel] = useState({
    enquirerID: "",
    name: "",
    email: "",
    phone: 0,
    companyName: "",
    enquiryStatus: "",
  });
  const [statusUpdate, setStatusUpdate] = useState(0);
  const [enquirerDetails, setEnquirerDetails] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [showComponent, setShowComponent] = useState(false); // State to manage delay
  const itemsPerPage = 10; // Set items per page to 1
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    import("../Css/styleVt.css");
    import("../Css/bootsnav.css");

    // Delay for 1 second before showing the component
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 100);
  });

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    ProjectServicesObj.GetEnquirerList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setEnquirerDetails([...res.data]);
        console.log("[...res.data]", [...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, [statusUpdate]);

  const ViewCart = (id) => {
    ProjectServicesObj.GetCartList(id)
      .then((res) => {
        if (res.data != null && res.data.length > 0) {
          setCartDetails([...res.data]);
        }
      })
      .catch((error) => {
        console.error("Error fetching cart details:", error);
      });
  };

  const handleToggle = (id, enquiryStatus) => {
    const updatedStatus = enquiryStatus === 1 ? 0 : 1;
    const res = ProjectServicesObj.SetProjectEnquiryStatus(id, updatedStatus);
    setStatusUpdate(res);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = enquirerDetails?.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return (
        <>
          <a style={{ marginRight: "50px" }}>Next</a>;
          {/* <a style={{ marginRight: "20px", marginBottom: "10px" }}>Next</a>; */}
        </>
      );
    }
    return originalElement;
  };

  if (!showComponent) {
    return null; // Or return a loading spinner, etc.
  }

  return (
    <>
      <Preloader />
      <HeaderDashboard />
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card grid">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Enquire's</h4>
                      {/* <a
                        href="#"
                        className="btn btn-primary btn-round ml-auto"
                        data-toggle="modal"
                        data-target="#addRowModal"
                      >
                        <i className="fa fa-plus"></i>
                        Add Banner
                      </a> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="display table table-striped table-hover LoginCenter"
                        id="AssociateDisplay"
                      >
                        <thead>
                          <tr>
                            <th>S. No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th style={{ textAlign: "center" }}>
                              View cart details
                            </th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {paginatedData != null && paginatedData.length > 0 ? (
                            paginatedData.map((v, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{v.name}</td>
                                    <td>{v.email}</td>
                                    <td>{v.phone}</td>
                                    <td>{v.companyName}</td>
                                    <td>
                                      <button
                                        type="button"
                                        //title=""
                                        className="btn btn-link "
                                        //data-original-title="Add"
                                        // style={{ color: "primary" }}
                                        onClick={() => ViewCart(v.enquirerID)}
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                      >
                                        <FaEye className="text-info" />
                                      </button>
                                    </td>
                                    <td>
                                      {/* <input
                                        type="checkbox"
                                        onClick={() =>
                                          handleToggle(
                                            v.enquirerID,
                                            v.enquiryStatus
                                          )
                                        }
                                        style={{
                                          backgroundColor:
                                            v.enquiryStatus === 1
                                              ? "red"
                                              : "green",
                                          color: "white",
                                          padding: "10px 20px",
                                          border: "none",
                                          borderRadius: "5px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {v.enquiryStatus === 1
                                          ? "Attended"
                                          : "UnAttended"}
                                      </input> */}

                                      <Space direction="vertical">
                                        <Switch
                                          className={
                                            v.enquiryStatus === 1
                                              ? "switch-checked"
                                              : "switch-unchecked"
                                          }
                                          checkedChildren="Attended"
                                          unCheckedChildren="UnAttended"
                                          //defaultChecked={v.enquiryStatus === 1}
                                          checked={v.enquiryStatus === 1}
                                          onClick={() =>
                                            handleToggle(
                                              v.enquirerID,
                                              v.enquiryStatus
                                            )
                                          }
                                        />
                                      </Space>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              {" "}
                              <tr>
                                <td colSpan="6">No Data in Table</td>
                              </tr>
                              {/* <label>No Data in Table</label> */}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        align="end"
        current={currentPage}
        total={enquirerDetails.length}
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        itemRender={itemRender}
        className="pagenationClass"
        // showTotal={(total, range) =>
        //   `${range[0]}-${range[1]} of ${total} items`
        // }
      />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Cart Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <table class="table table-bordered  text-center">
                <thead class="thead-dark">
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {cartDetails != null && cartDetails.length > 0 ? (
                    cartDetails.map((m, i) => (
                      <tr key={i}>
                        <td>{m.name}</td>
                        <td>{m.quantity}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No Cart Details</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a
                      className="text-primary"
                      href="https://vaanamtech.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Vaanam Technologies Pvt Ltd.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  );
};

export default ProductEnquirer;

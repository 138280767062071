import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class ProductCategoryServices {
  Create = async (data) => {    
    return Auth.post(`Project/CreateNewCategory`, data);
  };
  GetProjectList = async () => {
    return Auth.get(`Project/GetCategoryList`);
  };
  Delete = async (id) => {
    
    return Auth.get(`Project/DeleteCategory?id=${id}`);
  };
  CategoryFilesUpload = async (uploadfiles) => {
    return Auth.post(`Project/CategoryFilesUpload`, uploadfiles);
  };

  // Client_GetProjectList = async () => {
  //   return Auth.get(`Project/Client_GetProjectList`);
  // };

  // GetProjectClientImg = async (id) => {
  //   return Auth.get(`Project/GetProjectClintImg=${id}`);
  // };

  // DeleteClientLogo = async (id) => {
  //   return Auth.get(`Project/DeleteClientLogo?id=${id}`);
  // };
}

export default ProductCategoryServices;

import React from "react";

import AxiosServices from "../../../services/AxiosServices";

const Auth = new AxiosServices();

class ProjectEnquirerServices {
  GetEnquirerList = async () => {
    return Auth.get(`Project/GetEnquirerList`);
  };
  GetCartList = async (id) => {
    return Auth.get(`Project/GetCartList?id=${id}`);
  };
  SetProjectEnquiryStatus = async (id,enquiryStatus) => {
    return Auth.get(`Project/SetProjectEnquiryStatus?id=${id}&enquiryStatus=${enquiryStatus}`);
  };
}

export default ProjectEnquirerServices;

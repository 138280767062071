import React, { memo, useEffect, useState } from "react";
import CommonServices from "../../../services/CommonServices";
import { useNavigate } from "react-router-dom";
import CookieManager from "../common/CookieManager";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

function FeaturedProducts() {
  const [productName, setProductName] = useState("");
  const defaultProductQuandity = 1;
  const [isAdded, setIsAdded] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["products"]);

  var CommonServicesObj = new CommonServices();
  const [featureProducts, setFeatureProducts] = useState([]);

  useEffect(() => {
    CommonServicesObj.GetFeaturedProducts().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setFeatureProducts([...res.data]);
      }
    });
  }, []);

  const navigate = useNavigate();
  const handleImageClick = (id) => {
    navigate("/product", {
      state: { productId: id },
      //replace: true,
    });
  };
  const handleAddProduct = (addProduct, productName, quantity) => {
    setProductName(productName); // Set product name
    const result = addProduct(productName, quantity); // Add product with default quantity
    handleCartAlert(result);
  };

  const handleCartAlert = (e) => {
    if (e) {
      Swal.fire({
        title: "Product added Successfully!",
        text: "  ",
        icon: "success",
        timer: 1500, // Alert will close automatically after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        position: "top-end", // Position the alert in the top right corner
        toast: true, // Makes the alert look like a toast notification
      });
    } else {
      Swal.fire({
        title: "Product Already added",
        text: "",
        icon: "info",
        timer: 1500, // Alert will close automatically after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        position: "top-end", // Position the alert in the top right corner
        toast: true, // Makes the alert look like a toast notification
      });
    }
  };
  const handleIconHover = (name) => {
    //
    const products = cookies.products || [];
    const newVal = products.some((product) => product.name === name);
    setIsAdded(newVal);
  };

  return (
    <>
      <CookieManager>
        {({ addProduct }) => (
          <div>
            {/* Products Start */}
            <div className="container-fluid pt-5 pb-3">
              <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4">
                <span className="bg-secondary pr-3">Featured Products</span>
              </h2>
              <div className="row px-xl-5">
                {featureProducts.map((fp) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 pb-1"
                    key={fp.name}
                  >
                    <div className="product-item bg-light mb-4">
                      <div
                        className="product-img position-relative overflow-hidden d-flex justify-content-center align-items-center"
                        style={{
                          // width: "200px",
                          height: "300px",
                        }}
                      >
                        <img
                          className="img-fluid "
                          src={`${
                            process.env.REACT_APP_API_IMAGEPATH + fp.imageUrl
                          }`}
                          height={200}
                          width={200}
                          alt={fp.name}
                        />
                        <div className="product-action">
                          <a
                            className="btn btn-outline-dark btn-square"
                            //href
                            onClick={() =>
                              handleAddProduct(
                                addProduct,
                                fp.name,
                                defaultProductQuandity
                              )
                            }
                            onMouseEnter={() => handleIconHover(fp.name)}
                            title={
                              isAdded ? "Already added" : "Add to favorites"
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            aria-hidden="true"
                          >
                            <i className="fa fa-shopping-cart" />
                          </a>

                          <a
                            className="btn btn-outline-dark btn-square"
                            //href
                            onClick={() => handleImageClick(fp.projectID)}
                          >
                            <i className="fa fa-search" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="text-center py-4"
                        style={{ height: "80px" }}
                      >
                        <a
                          className="h6 text-decoration-none text-truncate"
                          style={{
                            display: "-webkit-box", // Makes the box display behave like a block container for text
                            WebkitBoxOrient: "vertical", // Sets the orientation to vertical for text
                            WebkitLineClamp: 2, // Limits the text to two lines
                            overflow: "hidden", // Hides overflowing content
                            textOverflow: "ellipsis", // Adds ellipsis when text overflows
                            wordWrap: "break-word", // Breaks long words onto the next line
                            whiteSpace: "normal", // Allows wrapping onto the next line
                            maxWidth: "100%", // Ensures text stays within container width
                            paddingLeft: "2px",
                            paddingRight: "2px",
                          }}
                          data-toggle="tooltip"
                          title={fp.name}
                        >
                          {fp.name}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Products End */}
          </div>
        )}
      </CookieManager>
    </>
  );
}

export default memo(FeaturedProducts);

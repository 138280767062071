import HeaderDashboard from "../DashBoard/HeaderDashboard";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "universal-cookie";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import Preloader from "../../Preloader.js";
import privacyServices from "../../../services/PrivacyServices";
import LoginView from "../Login/LoginView";
import ScrollToTop from "react-scroll-to-top";
var PrivacyServicesObj = new privacyServices();

const AdminPrivacy = () => {
  const [body, setBody] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [error, setformError] = useState({
    Privacy_Validate: "",
  });
  const [showComponent, setShowComponent] = useState(false); // State to manage delay

  useEffect(() => {
    import("../Css/styleVt.css");
    import("../Css/bootsnav.css");
  
    // Delay for 1 second before showing the component
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 100);
  });

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    PrivacyServicesObj.PrivacyList().then((res) => {
      if (res.data != null) {
        setBody(res.data.content);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSubmit = () => {
    var data = {
      SavedBY: parseInt(cookies.get("userID")),
      Content: body,
    };
    var myerror = error;
    var flag = true;
    var replce = body.replace(/(<([^>]+)>)/gi, "");
    if (replce == "") {
      Toast.fire({
        icon: "error",
        title: "Failed !",
      });
      flag = false;
    } else {
      myerror.Privacy_Validate = "";
    }
    setformError({ ...myerror });

    if (flag == true) {
      PrivacyServicesObj.Create(data).then((res) => {
        if (res.data == 1) {
          Toast.fire({
            icon: "success",
            title: "Save Success",
          });
          setTimeout(function () {
            window.location.reload();
          }, 750);
        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to Save!",
          });
        }
      });
      setIsLoaded(true);
    }
  };
  if (!showComponent) {
    return null; // Or return a loading spinner, etc.
  }
  return Auth ? (
    <>
      <Preloader />
      <HeaderDashboard />
      <div className="main-panel">
        <div className="content">
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card grid">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title"> Privacy &amp; Policy</h4>
                      {!isLoaded ? (
                        <a
                          href="#"
                          class="close"
                          className="btn btn-primary btn-round ml-auto"
                          data-toggle="modal"
                          data-target="#addRowModal"
                          onClick={onSubmit}
                        >
                          <i className="fa fa-file"></i>&nbsp;Save
                        </a>
                      ) : (
                        <button
                          class="btn btn-primary btn-round ml-auto"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      )}
                      <span className="text-danger">
                        {error.Privacy_Validate}
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <main>
                      <div className="form-container">
                        <form>
                          <ReactQuill
                            placeholder="spread your message..."
                            modules={AdminPrivacy.modules}
                            formats={AdminPrivacy.formats}
                            value={body}
                            onChange={setBody}
                          />
                        </form>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};

AdminPrivacy.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    ["code-block"],
    ["clean"],
  ],
};

AdminPrivacy.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "align",
  "color",
  "background",
  "link",
  "image",
  "code-block",
];

export default AdminPrivacy;

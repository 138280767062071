import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from "./components/user/common/NavBar";
import HomeIndex from "./components/user/home/HomeIndex";
import ContactUs from "./components/user/contactUs/ContactUs";
import Shop from "./components/user/shopping/Shop";
import ShoppingCart from "./components/user/shoppingCart/ShoppingCart";
import UserRegistrationForm from "./components/user/userRegistration/UserRegistrationForm";
import LoginView from "./components/admin/Login/LoginView";
import Dashboard from "./components/admin/DashBoard/Dashboard";
import HomeBanner from "./components/admin/HomeBanner/HomeBanner";
import ProjectsListTable from "./components/admin/Projects/ProjectsListTable";
import TermsListTable from "./components/admin/Terms/TermsListTable";
import AdminPrivacy from "./components/admin/Privacy/AdminPrivacy";
import ContactListTable from "./components/admin/Contact/ContactListTable";
import EmailTemplateList from "./components/admin/EmailTemplate/EmailTemplateList";
import ApplicationConfig from "./components/admin/ApplicationConfiguration/ApplicationConfig";
import ConfirmPassword from "./components/admin/ForgotPassWord/ChangePassword";
import ForgotView from "./components/admin/ForgotPassWord/ForgotPassWord";
import ProductDescription from "./components/user/productDescription/ProductDescription";
import ProductCategoryListTable from "./components/admin/ProductCategory/ProductCategoryListTable";
import ProductEnquirer from "./components/admin/ProductEnquirer/ProductEnquirer";
import SubscribersListTable from "./components/admin/NewsLetter/NewsLetter";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route className="userSide" element={<NavBar />}>
            <Route path="/" element={<HomeIndex />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/shop" element={<Shop />} />
            {/* <Route path="/productDetails" element={<ProductDescription />} /> */}
            <Route path="/product" element={<ProductDescription />} />
          </Route>

          <Route>
            <Route path="/registration" element={<UserRegistrationForm />} />
          </Route>

          <Route className="adminSide">
            <Route path="/Admin" element={<LoginView />} />
            <Route path="/Dashboardview" element={<Dashboard />} />
            <Route path="/HomeBanner" element={<HomeBanner />} />
            <Route path="/ProductList" element={<ProjectsListTable />} />
            <Route path="/Category" element={<ProductCategoryListTable />} />
            <Route path="/AdminTerms" element={<TermsListTable />} />
            <Route path="/AdminPrivacy" element={<AdminPrivacy />} />
            <Route path="/ContactListTable" element={<ContactListTable />} />
            <Route path="/EmailTemplateList" element={<EmailTemplateList />} />
            <Route path="/ApplicationConfig" element={<ApplicationConfig />} />
            <Route path="/ChangePassword" element={<ConfirmPassword />} />
            <Route path="/ForgotView" element={<ForgotView />} />
            <Route path="/Enquiry" element={<ProductEnquirer />} />
            <Route path="/NewsLetter" element={<SubscribersListTable />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
// import AxiosServices from "../services/AxiosServices";
import AxiosServices from "./AxiosServices";


const Auth = new AxiosServices();


class EmailTemplateService {

     getData = async () => {
        
          return await Auth.get('/EmailTemplate/GetEMailPartial')        
    };
    Create = async (EmpID) => {
     return Auth.get(`/Employee/Create?id=${EmpID}`)        
     };
     CreateUpdateEmailtemplate = async (obj) => {
       
          return await Auth.post(`/EmailTemplate/CreateUpdateEmailtemplate`,obj)        
          
     };
     DeleteTemplate = async (val) => {
          return await Auth.get(`/EmailTemplate/EmailDelete?id=${Number(val)}&ModifiedBy=${val}`,)        
     };

     GetModuleNameChanged = async (id) => {
        
          return await Auth.get(`/EmailTemplate/ModuleNameChanged?id=${Number(id)}`,)        
     };

     Createtemplate = async (EmpID) => {
          return Auth.get(`/EmailTemplate/Index?id=${EmpID}`,)        
     };
     
     GetModuleFuncNameChanged = async (Ids) => {
          return Auth.get(`/EmailTemplate/ModuleFuncNameChanged?id=${Number(Ids)}`,)        
     };


     
}

export default EmailTemplateService


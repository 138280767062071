import React, { useState, useEffect } from "react";
import Carousel from "../common/Carousel";
import Featured from "../common/Featured";
import Categories from "../common/Categories";
import FeaturedProducts from "../common/FeaturedProducts";
import RecentProducts from "../common/RecentProducts";
import Clients from "../common/Clients";
import Preloader from "../../Preloader";

const HomeIndex = () => {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 3000);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Preloader />
      <Carousel></Carousel>
      {/* <Featured></Featured> */}
      <Categories></Categories>
      <FeaturedProducts></FeaturedProducts>
      <RecentProducts></RecentProducts>
      {/* <Clients></Clients> */}
    </>
  );
};

export default HomeIndex;

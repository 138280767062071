import Breadcrumb from "../common/Breadcrumb";
import { BackToTop } from "../common/Footer";
import Contact from "../common/Contact";
import React, { useEffect } from "react";
import Preloader from "../../Preloader";
function ContactUs() {
  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1500);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Preloader />
      <Breadcrumb></Breadcrumb>
      <Contact></Contact>
    </>
  );
}

export default ContactUs;

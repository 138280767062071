import React, { useState, useEffect } from "react";
import Breadcrumb from "../common/Breadcrumb";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/OwlCarousel";
import ProjectServices from "../../../services/ProjectServices";
import { useLocation } from "react-router-dom";
import { Carousel } from "antd";
import ReactPlayer from "react-player";
import { Fullscreen } from "@material-ui/icons";
import CookieManager from "../common/CookieManager";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Preloader from "../../Preloader";
import { merge } from "jquery";

const ProductDescription = () => {
  const ProjectServicesObj = new ProjectServices();
  const location = useLocation();
  const [id, setid] = useState(location.state?.productId ?? 0);
  const [product, setProduct] = useState([]);
  const [productName, setProductName] = useState("");

  const defaultProductQuandity = 1;
  const [cookies, setCookie, removeCookie] = useCookies(["products"]);

  const [ProjectDetails, setProjectDetails] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 2000);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    ProjectServicesObj.GetProductListByProductID(id).then((res) => {
      if (res.data != null && res.data.length > 0) {
        const project = res.data[0];
        console.log("project", project.profilePic); // Accessing the first (and likely only) project in the array
        setSelectedContent(
          process.env.REACT_APP_API_IMAGEPATH + project.profilePic
        );
        setProduct(project);
      }
    });
  }, [id]);

  useEffect(() => {
    const fetchAllProducts = () => {
      ProjectServicesObj.Client_GetProjectList().then((res) => {
        if (res.data != null && res.data.length > 0) {
          console.log("res.data", res.data);
          setProjectDetails([...res.data]);
          setTimeout(() => {
            $("#preloader").hide();
          }, 2000);
        }
      });
    };
    fetchAllProducts();
  }, []);

  // const options = {
  //   loop: true,
  //   margin: 10,
  //   nav: false,
  //   autoplay: true,
  //   smartSpeed: 1000,
  //   responsive: {
  //     0: { items: 2 },
  //     576: { items: 3 },
  //     768: { items: 4 },
  //     992: { items: 5 },
  //     1200: { items: 6 },
  //   },
  // };

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 2500,
    //autoplayHoverPause: true,
    //smartSpeed: 3000,
    autoWidth: true,
    // rewind: true,
    // responsiveClass: true,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: { items: 2 },
      576: { items: 3 },
      768: { items: 4 },
      992: { items: 5 },
      1200: { items: 6 },
    },
  };

  const handleAddProduct = (addProduct, productName, quantity) => {
    setProductName(productName); // Set product name
    const result = addProduct(productName, quantity); // Add product with default quantity
    handleCartAlert(result);
  };

  const handleIconHover = (name) => {
    const products = cookies.products || [];
    const newVal = products.some((product) => product.name === name);
    setIsAdded(newVal);
  };

  const handleCartAlert = (e) => {
    if (e) {
      Swal.fire({
        title: "Product added Successfully!",
        text: "  ",
        icon: "success",
        timer: 1500, // Alert will close automatically after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        position: "top-end", // Position the alert in the top right corner
        toast: true, // Makes the alert look like a toast notification
      });
    } else {
      Swal.fire({
        title: "Product Already added",
        text: "",
        icon: "info",
        timer: 1500, // Alert will close automatically after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        position: "top-end", // Position the alert in the top right corner
        toast: true, // Makes the alert look like a toast notification
      });
    }
  };

  const [selectedContent, setSelectedContent] = useState(null);
  const [isVideo, setIsVideo] = useState(false);

  const handleImageClick = (imageUrl) => {
    setSelectedContent(imageUrl);
    setIsVideo(false);
  };

  const handleVideoClick = (videoUrl) => {
    setSelectedContent(videoUrl);
    setIsVideo(true);
  };

  const navigate = useNavigate();

  const handleImageClickNav = (id) => {
    setid(id);
    // navigate("/product", {
    //   state: { productId: id },
    //   replace: true,
    // });
  };

  return (
    <>
      <Preloader />
      <CookieManager>
        {({ addProduct }) => (
          <>
            <Breadcrumb></Breadcrumb>

            {/* Shop Detail Start */}
            <div className="container-fluid pb-5">
              <div className="row px-xl-5">
                {/* Product picture start*/}
                <div className="col-lg-5 mb-30">
                  <div style={{ height: "100%" }}>
                    <div style={{ height: "auto" }}>
                      <div className="display" style={{ textAlign: "center" }}>
                        {selectedContent && !isVideo && (
                          <img
                            className="productDiscriptionImage"
                            src={selectedContent}
                            alt="Selected"
                            //style={{ height: "400px" }}
                          />
                        )}

                        {selectedContent && isVideo && (
                          <ReactPlayer
                            //className="productDiscriptionVideo"
                            url={selectedContent}
                            controls={true}
                            width=""
                            height="400px"
                            playing={true} // Automatically play the video
                            style={{}}
                          />
                        )}
                      </div>
                      {/* <Carousel arrows>
                        {product.imageUrls?.map((image, index) => {
                          const imageUrl = image.imageUrl.toLowerCase();
                          return (
                            <img
                              key={index}
                              src={`${
                                process.env.REACT_APP_API_IMAGEPATH + imageUrl
                              }`}
                              height={500}
                              width={400}
                              alt={`Product image ${index + 1}`}
                              onClickItem
                            />
                          );
                        })}
                        <ReactPlayer
                          url={product.websiteUrl}
                          height={500}
                          width={400}
                          controls={true}
                        />
                      </Carousel> */}
                    </div>
                    <div
                      style={{
                        //height: "20%",
                        width: "100%",
                        display: "flex",
                        //alignItems: "center",
                        overflowX: "auto",
                        overflowY: "hidden",
                      }}
                    >
                      {/* <Carousel arrows> */}
                      <div
                        style={{
                          // width: "100%",
                          //height: "20%",
                          display: "flex",
                        }}
                        //className="row"
                      >
                        <div style={{ width: "100", height: "100" }}>
                          <img
                            src={`${
                              process.env.REACT_APP_API_IMAGEPATH +
                              product.profilePic
                            }`}
                            alt=""
                            onClick={() =>
                              handleImageClick(
                                `${
                                  process.env.REACT_APP_API_IMAGEPATH +
                                  product.profilePic
                                }`
                              )
                            }
                            style={{
                              border: "2px solid black",
                              margin: "10px",
                              padding: "5px",
                            }}
                            height={90}
                            width={90}
                          />
                        </div>

                        {product.imageUrls?.map((image, index) => {
                          const imageUrl = image.imageUrl.toLowerCase();
                          return (
                            <div style={{ width: "100", height: "100" }}>
                              <img
                                key={index}
                                src={`${
                                  process.env.REACT_APP_API_IMAGEPATH + imageUrl
                                }`}
                                style={{
                                  border: "2px solid black",
                                  margin: "10px",
                                  padding: "5px",
                                }}
                                //style={{height:"10%"}}
                                height={90}
                                width={90}
                                alt={`Product image ${index + 1}`}
                                onClick={() =>
                                  handleImageClick(
                                    `${
                                      process.env.REACT_APP_API_IMAGEPATH +
                                      imageUrl
                                    }`
                                  )
                                }
                                onClickItem
                              />
                            </div>
                          );
                        })}
                        {/* <div
                          onClick={() => handleVideoClick(product.websiteUrl)}
                          style={{
                            border: "2px solid black",
                            margin: "10px",
                            padding: "5px",
                          }}
                        > */}
                        <div style={{ width: "100", height: "100" }}>
                          <img
                            //className="img-fluid float-left"
                            //  style={{width: '80', height: '80'}}
                            height={90}
                            width={90}
                            // src="/assets/img/LG_logo-noBG.png"
                            src="/assets/img/youtube-icon-square.png"
                            alt=""
                            onClick={() => handleVideoClick(product.websiteUrl)}
                            style={{
                              border: "2px solid black",
                              margin: "10px",
                              padding: "5px",
                            }}
                          />
                        </div>

                        {/* <i className="fa-brands fa-youtube"
               onClick={() => handleVideoClick(product.websiteUrl)}
               height={100}
                width={100}
              ></i> */}

                        {/* <ReactPlayer
                style={{
                  border: "2px solid black",
                  margin: "10px",
                  padding: "5px",
                }}
                url={product.websiteUrl}
                height={100}
                width={100}
                controls={false}
                playing={false}
                onClick={() => handleVideoClick(product.websiteUrl)} // Disable controls for the thumbnail
              /> */}
                        {/* </div> */}
                      </div>
                      {/* </Carousel> */}
                    </div>
                  </div>

                  {/* <div
        id="product-carousel"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner bg-light">
          <div className="carousel-item active">
            <img
              className="w-100 h-100"
              src="assets/img/product-5.jpg"
              alt="Image"
            />
            <Carousel></Carousel>
          </div>
          <div className="carousel-item">
            <img
              className="w-100 h-100"
              src="img/product-2.jpg"
              alt="Image"
            />
          </div>
          <div className="carousel-item">
            <img
              className="w-100 h-100"
              src="img/product-3.jpg"
              alt="Image"
            />
          </div>
          <div className="carousel-item">
            <img
              className="w-100 h-100"
              src="assets/img/product-4.jpg"
              alt="Image"
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#product-carousel"
          data-slide="prev"
        >
          <i className="fa fa-2x fa-angle-left text-dark" />
        </a>
        <a
          className="carousel-control-next"
          href="#product-carousel"
          data-slide="next"
        >
          <i className="fa fa-2x fa-angle-right text-dark" />
        </a>
      </div> */}
                </div>
                {/* Product picture end*/}

                {/* Product disctiption start*/}
                <div className="col-lg-7 h-auto mb-30">
                  <div
                    className="h-100 bg-light p-30"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <h1>{product.name}</h1>
                    <div className="d-flex mb-3">
                      {/* <div className="text-primary mr-2">
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star-half-alt" />
                        <small className="far fa-star" />
                      </div> */}
                      {/* <small className="pt-1">(99 Reviews)</small> */}
                    </div>
                    {/* <h3 className="font-weight-semi-bold mb-4">$150.00</h3> */}
                    <p>{product.description}</p>
                    {/* <p className="mb-4">
          Volup erat ipsum diam elitr rebum et dolor. Est nonumy elitr
          erat diam stet sit clita ea. Sanc ipsum et, labore clita lorem
          magna duo dolor no sea Nonumy
        </p> */}
                    {/* <div className="d-flex mb-3">
            <strong className="text-dark mr-3">Sizes:</strong>
            <form>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="size-1" name="size" />
                <label className="custom-control-label" htmlFor="size-1">XS</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="size-2" name="size" />
                <label className="custom-control-label" htmlFor="size-2">S</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="size-3" name="size" />
                <label className="custom-control-label" htmlFor="size-3">M</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="size-4" name="size" />
                <label className="custom-control-label" htmlFor="size-4">L</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="size-5" name="size" />
                <label className="custom-control-label" htmlFor="size-5">XL</label>
              </div>
            </form>
          </div> */}
                    {/* <div className="d-flex mb-4">
            <strong className="text-dark mr-3">Colors:</strong>
            <form>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="color-1" name="color" />
                <label className="custom-control-label" htmlFor="color-1">Black</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="color-2" name="color" />
                <label className="custom-control-label" htmlFor="color-2">White</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="color-3" name="color" />
                <label className="custom-control-label" htmlFor="color-3">Red</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="color-4" name="color" />
                <label className="custom-control-label" htmlFor="color-4">Blue</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input type="radio" className="custom-control-input" id="color-5" name="color" />
                <label className="custom-control-label" htmlFor="color-5">Green</label>
              </div>
            </form>
          </div>  */}

                    <div
                      // className="d-flex align-items-baseline justify-content-center mb-4 pt-2"
                      className="d-flex align-items-center justify-content-center mb-4 pt-2"
                      style={{ marginTop: "auto" }}
                    >
                      {/* <div
            className="input-group quantity mr-3"
            style={{ width: "130px" }}
          >
            <div className="input-group-btn">
              <button className="btn btn-primary btn-minus">
                <i className="fa fa-minus" />
              </button>
            </div>
            <input
              type="text"
              className="form-control bg-secondary border-0 text-center"
              defaultValue={1}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary btn-plus">
                <i className="fa fa-plus" />
              </button>
            </div>
          </div> */}
                      <button
                        className="btn btn-primary px-3"
                        onClick={() =>
                          handleAddProduct(
                            addProduct,
                            product.name,
                            defaultProductQuandity
                          )
                        }
                      >
                        <i className="fa fa-shopping-cart mr-1" /> Add To Cart
                      </button>
                    </div>

                    {/* <div className="d-flex pt-2">
            <strong className="text-dark mr-2">Share on:</strong>
            <div className="d-inline-flex">
              <a className="text-dark px-2" href>
                <i className="fab fa-facebook-f" />
              </a>
              <a className="text-dark px-2" href>
                <i className="fab fa-twitter" />
              </a>
              <a className="text-dark px-2" href>
                <i className="fab fa-linkedin-in" />
              </a>
              <a className="text-dark px-2" href>
                <i className="fab fa-pinterest" />
              </a>
            </div>
          </div> */}
                  </div>
                </div>
                {/* Product disctiption end*/}
              </div>
            </div>
            {/* Shop Detail End */}

            {/* Products Start */}
            <div className="container py-5">
              <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4">
                <span className="bg-secondary pr-3">You May Also Like</span>
              </h2>
              <div className="row px-xl-5 container">
                <div className="col">
                  <OwlCarousel
                    options={options}
                    className=" related-carousel owl-carousel "
                  >
                    {ProjectDetails != null && ProjectDetails.length > 0 ? (
                      ProjectDetails.map((fp, i) => {
                        return (
                          <div
                            key={i}
                            className="product-item bg-light "
                            style={{ width: "180px" }}
                          >
                            <div
                              className="item bg-light p-4"
                              style={{ height: "180px" }}
                            >
                              <div
                              //style={{ height: "-webkit-fill-available" }}
                              >
                                <img
                                  className="img-fluid "
                                  src={`${
                                    process.env.REACT_APP_API_IMAGEPATH +
                                    fp.imageUrl
                                  }`}
                                  alt={fp.name}
                                  style={{
                                    height: "120px",
                                    objectFit: "cover !important",
                                  }}
                                />
                              </div>

                              <div className="product-action">
                                <a
                                  className="btn btn-outline-dark btn-square"
                                  href
                                  onClick={() =>
                                    handleAddProduct(
                                      addProduct,
                                      fp.name,
                                      defaultProductQuandity
                                    )
                                  }
                                  onMouseEnter={() => handleIconHover(fp.name)}
                                  title={
                                    isAdded
                                      ? "Already added"
                                      : "Add to favorites"
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  aria-hidden="true"
                                >
                                  <i className="fa fa-shopping-cart" />
                                </a>

                                <a
                                  className="btn btn-outline-dark btn-square"
                                  href
                                  onClick={() =>
                                    handleImageClickNav(fp.projectID)
                                  }
                                >
                                  <i className="fa fa-search" />
                                </a>
                              </div>
                            </div>
                            <div
                              className="text-center py-4"
                              style={{ height: "80px" }}
                            >
                              <a
                                className="h6 text-decoration-none text-truncate"
                                style={{
                                  display: "-webkit-box", // Makes the box display behave like a block container for text
                                  WebkitBoxOrient: "vertical", // Sets the orientation to vertical for text
                                  WebkitLineClamp: 2, // Limits the text to two lines
                                  overflow: "hidden", // Hides overflowing content
                                  textOverflow: "ellipsis", // Adds ellipsis when text overflows
                                  wordWrap: "break-word", // Breaks long words onto the next line
                                  whiteSpace: "normal", // Allows wrapping onto the next line
                                  maxWidth: "100%", // Ensures text stays within container width
                                  paddingLeft: "2px",
                                  paddingRight: "2px",
                                }}
                                data-toggle="tooltip"
                                title={fp.name}
                              >
                                {fp.name}
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}

                    {/* <div className="product-item bg-light" style={{ width: "180px" }}>
          <div className="item bg-light p-4">
            <img
              className="img-fluid "
              style={{ height: "100px" }}
              src="assets/img/product-1.jpg"
              alt=""
            />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-shopping-cart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="far fa-heart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-sync-alt" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
          <div className="text-center py-4">
            <a
              className="h6 text-decoration-none text-truncate product-name"
              href
            >
              Product Name Goes Here
            </a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5>
              <h6 className="text-muted ml-2">
                <del>$123.00</del>
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light" style={{ width: "180px" }}>
          <div className="item bg-light p-4">
            <img
              className="img-fluid "
              style={{ height: "100px" }}
              src="assets/img/product-2.jpg"
              alt=""
            />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-shopping-cart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="far fa-heart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-sync-alt" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
          <div className="text-center py-4">
            <a
              className="h6 text-decoration-none text-truncate product-name"
              href
            >
              Product Name Goes Here
            </a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5>
              <h6 className="text-muted ml-2">
                <del>$123.00</del>
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light" style={{ width: "180px" }}>
          <div className="item bg-light p-4">
            <img
              className="img-fluid "
              style={{ height: "100px" }}
              src="assets/img/product-3.jpg"
              alt=""
            />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-shopping-cart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="far fa-heart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-sync-alt" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
          <div className="text-center py-4">
            <a
              className="h6 text-decoration-none text-truncate product-name"
              href
            >
              Product Name Goes Here
            </a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5>
              <h6 className="text-muted ml-2">
                <del>$123.00</del>
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light" style={{ width: "180px" }}>
          <div className="item bg-light p-4">
            <img
              className="img-fluid "
              style={{ height: "100px" }}
              src="assets/img/product-4.jpg"
              alt=""
            />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-shopping-cart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="far fa-heart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-sync-alt" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
          <div className="text-center py-4">
            <a
              className="h6 text-decoration-none text-truncate product-name"
              href
            >
              Product Name Goes Here
            </a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5>
              <h6 className="text-muted ml-2">
                <del>$123.00</del>
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light" style={{ width: "180px" }}>
          <div className="item bg-light p-4">
            <img
              className="img-fluid "
              style={{ height: "100px" }}
              src="assets/img/product-5.jpg"
              alt=""
            />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-shopping-cart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="far fa-heart" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-sync-alt" />
              </a>
              <a className="btn btn-outline-dark btn-square" href>
                <i className="fa fa-search" />
              </a>
            </div>
          </div>
          <div className="text-center py-4">
            <a
              className="h6 text-decoration-none text-truncate product-name"
              href
            >
              Product Name Goes Here
            </a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5>
              <h6 className="text-muted ml-2">
                <del>$123.00</del>
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div> */}
                  </OwlCarousel>

                  {/* <div className="owl-carousel related-carousel">
        <div className="product-item bg-light">

          <div className="product-img position-relative overflow-hidden">
            <img className="img-fluid w-100" src="img/product-1.jpg" alt="" />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-shopping-cart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="far fa-heart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-sync-alt" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-search" /></a>
            </div>
          </div>
          <div className="text-center py-4">
            <a className="h6 text-decoration-none text-truncate" href>Product Name Goes Here</a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>

        </div>
        <div className="product-item bg-light">
          <div className="product-img position-relative overflow-hidden">
            <img className="img-fluid w-100" src="img/product-2.jpg" alt="" />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-shopping-cart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="far fa-heart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-sync-alt" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-search" /></a>
            </div>
          </div>
          <div className="text-center py-4">
            <a className="h6 text-decoration-none text-truncate" href>Product Name Goes Here</a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light">
          <div className="product-img position-relative overflow-hidden">
            <img className="img-fluid w-100" src="img/product-3.jpg" alt="" />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-shopping-cart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="far fa-heart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-sync-alt" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-search" /></a>
            </div>
          </div>
          <div className="text-center py-4">
            <a className="h6 text-decoration-none text-truncate" href>Product Name Goes Here</a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light">
          <div className="product-img position-relative overflow-hidden">
            <img className="img-fluid w-100" src="img/product-4.jpg" alt="" />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-shopping-cart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="far fa-heart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-sync-alt" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-search" /></a>
            </div>
          </div>
          <div className="text-center py-4">
            <a className="h6 text-decoration-none text-truncate" href>Product Name Goes Here</a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
        <div className="product-item bg-light">
          <div className="product-img position-relative overflow-hidden">
            <img className="img-fluid w-100" src="img/product-5.jpg" alt="" />
            <div className="product-action">
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-shopping-cart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="far fa-heart" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-sync-alt" /></a>
              <a className="btn btn-outline-dark btn-square" href><i className="fa fa-search" /></a>
            </div>
          </div>
          <div className="text-center py-4">
            <a className="h6 text-decoration-none text-truncate" href>Product Name Goes Here</a>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <h5>$123.00</h5><h6 className="text-muted ml-2"><del>$123.00</del></h6>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-1">
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small className="fa fa-star text-primary mr-1" />
              <small>(99)</small>
            </div>
          </div>
        </div>
      </div> */}
                </div>
              </div>
            </div>
            {/* Products End */}
          </>
        )}
      </CookieManager>
    </>
  );
};

export default ProductDescription;

<div className="row px-xl-5">
  <div className="col">
    <div className="bg-light p-30">
      <div className="nav nav-tabs mb-4">
        <a
          className="nav-item nav-link text-dark active"
          data-toggle="tab"
          href="#tab-pane-1"
        >
          Description
        </a>
        {/* <a
                  className="nav-item nav-link text-dark"
                  data-toggle="tab"
                  href="#tab-pane-2"
                >
                  Information
                </a> */}
        {/* <a className="nav-item nav-link text-dark" data-toggle="tab" href="#tab-pane-3">Reviews (0)</a> */}
      </div>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="tab-pane-1">
          <h4 className="mb-3">Product Description</h4>
          <p>
            Eos no lorem eirmod diam diam, eos elitr et gubergren diam sea.
            Consetetur vero aliquyam invidunt duo dolores et duo sit. Vero diam
            ea vero et dolore rebum, dolor rebum eirmod consetetur invidunt sed
            sed et, lorem duo et eos elitr, sadipscing kasd ipsum rebum diam.
            Dolore diam stet rebum sed tempor kasd eirmod. Takimata kasd ipsum
            accusam sadipscing, eos dolores sit no ut diam consetetur duo justo
            est, sit sanctus diam tempor aliquyam eirmod nonumy rebum dolor
            accusam, ipsum kasd eos consetetur at sit rebum, diam kasd invidunt
            tempor lorem, ipsum lorem elitr sanctus eirmod takimata dolor ea
            invidunt.
          </p>
          <p>
            Dolore magna est eirmod sanctus dolor, amet diam et eirmod et ipsum.
            Amet dolore tempor consetetur sed lorem dolor sit lorem tempor.
            Gubergren amet amet labore sadipscing clita clita diam clita. Sea
            amet et sed ipsum lorem elitr et, amet et labore voluptua sit rebum.
            Ea erat sed et diam takimata sed justo. Magna takimata justo et amet
            magna et.
          </p>
        </div>
        {/* <div className="tab-pane fade" id="tab-pane-2">
                  <h4 className="mb-3">Additional Information</h4>
                  <p>
                    Eos no lorem eirmod diam diam, eos elitr et gubergren diam
                    sea. Consetetur vero aliquyam invidunt duo dolores et duo
                    sit. Vero diam ea vero et dolore rebum, dolor rebum eirmod
                    consetetur invidunt sed sed et, lorem duo et eos elitr,
                    sadipscing kasd ipsum rebum diam. Dolore diam stet rebum sed
                    tempor kasd eirmod. Takimata kasd ipsum accusam sadipscing,
                    eos dolores sit no ut diam consetetur duo justo est, sit
                    sanctus diam tempor aliquyam eirmod nonumy rebum dolor
                    accusam, ipsum kasd eos consetetur at sit rebum, diam kasd
                    invidunt tempor lorem, ipsum lorem elitr sanctus eirmod
                    takimata dolor ea invidunt.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item px-0">
                          Sit erat duo lorem duo ea consetetur, et eirmod
                          takimata.
                        </li>
                        <li className="list-group-item px-0">
                          Amet kasd gubergren sit sanctus et lorem eos
                          sadipscing at.
                        </li>
                        <li className="list-group-item px-0">
                          Duo amet accusam eirmod nonumy stet et et stet eirmod.
                        </li>
                        <li className="list-group-item px-0">
                          Takimata ea clita labore amet ipsum erat justo
                          voluptua. Nonumy.
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item px-0">
                          Sit erat duo lorem duo ea consetetur, et eirmod
                          takimata.
                        </li>
                        <li className="list-group-item px-0">
                          Amet kasd gubergren sit sanctus et lorem eos
                          sadipscing at.
                        </li>
                        <li className="list-group-item px-0">
                          Duo amet accusam eirmod nonumy stet et et stet eirmod.
                        </li>
                        <li className="list-group-item px-0">
                          Takimata ea clita labore amet ipsum erat justo
                          voluptua. Nonumy.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
        {/* <div className="tab-pane fade" id="tab-pane-3">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="mb-4">1 review for "Product Name"</h4>
                        <div className="media mb-4">
                          <img src="img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{width: '45px'}} />
                          <div className="media-body">
                            <h6>John Doe<small> - <i>01 Jan 2045</i></small></h6>
                            <div className="text-primary mb-2">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star-half-alt" />
                              <i className="far fa-star" />
                            </div>
                            <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h4 className="mb-4">Leave a review</h4>
                        <small>Your email address will not be published. Required fields are marked *</small>
                        <div className="d-flex my-3">
                          <p className="mb-0 mr-2">Your Rating * :</p>
                          <div className="text-primary">
                            <i className="far fa-star" />
                            <i className="far fa-star" />
                            <i className="far fa-star" />
                            <i className="far fa-star" />
                            <i className="far fa-star" />
                          </div>
                        </div>
                        <form>
                          <div className="form-group">
                            <label htmlFor="message">Your Review *</label>
                            <textarea id="message" cols={30} rows={5} className="form-control" defaultValue={""} />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Your Name *</label>
                            <input type="text" className="form-control" id="name" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Your Email *</label>
                            <input type="email" className="form-control" id="email" />
                          </div>
                          <div className="form-group mb-0">
                            <input type="submit" defaultValue="Leave Your Review" className="btn btn-primary px-3" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}
      </div>
    </div>
  </div>
</div>;

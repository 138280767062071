import React, { memo, useState, useEffect } from "react";
import BannerServices from "../../../services/BannerServices";
import styled, { keyframes } from "styled-components";
import { slideInLeft } from "react-animations";
import "./css/Carousel.css";
import { Outlet, Link } from "react-router-dom";

const SlideLeft = styled.div`
  animation: 2s ${keyframes`${slideInLeft}}`};
`;

var BannerServicesObj = new BannerServices();

function Carousel() {
  const [SliderDetails, setSliderDetails] = useState([]);
  const [length, setLength] = useState(0);
  const apiImagePath = process.env.REACT_APP_API_IMAGEPATH;
  useEffect(() => {
    BannerServicesObj.Client_GetSliderList()

      .then((res) => {
        if (res.data != null && res.data.length > 0) {
          setSliderDetails([...res.data]);
          // console.log([...res.data], "rttff");

          const Imagecount = res?.data.filter(
            (e) => e.imageUrl !== null
          ).length;
          setLength(Imagecount);
          setTimeout(() => {
            $("#preloader").hide();
          }, 3000);
        }
      })
      .catch(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      });
  }, []);

  return (
    <>
      <div className="container-fluid mb-3">
        <div className="row px-xl-5">
          <div className="col-lg-12">
            <div
              id="header-carousel"
              className="carousel slide carousel-fade mb-30 mb-lg-0"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#header-carousel"
                  data-slide-to={0}
                  className="active"
                />
                <li data-target="#header-carousel" data-slide-to={1} />
                <li data-target="#header-carousel" data-slide-to={2} />
              </ol>
              <div className="carousel-inner">
                {/* <div className="carousel-item position-relative active" style={{height: '430px'}}> */}

                <div>
                  {SliderDetails != null && SliderDetails.length > 0 ? (
                    SliderDetails.map((v, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            i == 0
                              ? "carousel-item position-relative bg-cover active"
                              : "carousel-item position-relative bg-cover"
                          }
                          style={{ height: "430px" }}
                        >
                          <img
                            className=" w-100 h-100"
                            src={`${
                              process.env.REACT_APP_API_IMAGEPATH + v.imageUrl
                            }`}
                            style={{ objectFit: "cover" }}
                          />
                          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{ maxWidth: "1000px" }}>
                              <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">
                                {v.title}
                              </h1>
                              <p className="mx-md-5 px-5 animate__animated animate__bounceIn">
                                {v.description}
                              </p>
                              {/* <a
                                className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp"
                                href={v.sliderUrl}
                                target={"_blank"}
                              >
                                Shop Now
                              </a> */}
                              <Link
                                to={"/shop"}
                                className=" btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp"
                              >
                                Shop Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Carousel);

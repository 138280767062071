import Swal from "sweetalert2";
import React, { useState, useEffect, useReducer } from "react";
import "react-tabs/style/react-tabs.css";
import { useLocation, useNavigate } from "react-router-dom";
import ApplicationConfigServices from "../../../services/ApplicationConfigServices";



const ApplicationConfigServicesObj = new ApplicationConfigServices();
const H1BConfig = ({H1BList,getConfigLists}) => {
    
    const [isLoaded, setIsLoaded] = useState(false);
    let H1bValue = H1BList?.filter((list) => {
        return list?.moduleCode == 'H1B';
    });
    const [fromEmail,setFromEmail]=useState(H1bValue[0]?.fromMail)
    const [toEmail,setToEmail]=useState(H1bValue[0]?.toInternalMail);
    const navigate = useNavigate();
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const SumbitH1b = async (data) => {
        
        var flag = true;
        data.preventDefault();
        const fromEmail = document.getElementById('H1BFromMail').value.trim();
        const toMail = document.getElementById('H1BToMail').value.trim();
        if (fromEmail === '') {

            document.getElementById('fromMailErr').innerText = 'Please enter the frommail';
            flag = false;
            
           // firstErrorElement = document.getElementById('fromMailErr');

        }
        if (toMail === '') {

            document.getElementById('toMailErr').innerText = 'Please enter the toMail';
            flag = false;
            
           // firstErrorElement = document.getElementById('fromMailErr');

        }
        
        if (flag === true) {
            const FromEmail=fromEmail
            const ToEmail=toMail
            const ID =H1bValue[0]?.emailConfigId

            ApplicationConfigServicesObj.UpdateEmails(ID,FromEmail,ToEmail).then((res) => {
                
                if(res.data==1){
                    Toast.fire({
                        icon: "success",
                        title: "Updated Successfully ",
                    });
                    getConfigLists();
                    setTimeout(function () {
                        //window.location.reload();
                       // window.location.href="/ApplicationConfig"
                       

                    }, 2000);
                }

            })
        }
    }

    const fromEmailChange=(e,name)=>{
        document.getElementById('fromMailErr').innerText = '';
        var val=e?.target?.value
        if(name=='H1BFromMail'){
            const atIndex = val.indexOf('.com');
            if (atIndex !== -1) {
               
                val = val.slice(0, atIndex + 4); 
            }
            setFromEmail(val)
        }
    }
    const toEmailChange=(e,name)=>{
        document.getElementById('toMailErr').innerText = '';
        var val=e?.target?.value
        if(name=='H1BToMail'){
            setToEmail(val)
        }
    }
 
  
 
    
    return (
        <>
            <div className="table-responsive">
                <form onSubmit={SumbitH1b}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>
                                Auto Responce Sender ID<span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    id="H1BFromMail"
                                    className="form-control"
                                    placeholder="sample@vaanamtech.com"
                                    name="H1BFromMail"
                                    value={fromEmail}
                               
                                    onChange={(e)=>fromEmailChange(e,'H1BFromMail')}
                                />
                                <span id="fromMailErr" className="text-danger"></span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="form-group  ">
                                <label>
                                Applicants Info to Internal Members<span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    id="H1BToMail"
                                    className="form-control"
                                    placeholder=""
                                    name="H1BToMail"
                                    value={toEmail}
                                    onChange={(e)=>toEmailChange(e,'H1BToMail')}
                                />
                               
                                <span id="toMailErr" className="text-danger"></span><br></br>
                                <span className="ToSpan" >Note: If Enter more than one email address, separating them with commas(,)</span>
                            </div>

                        </div>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary" style={{ cursor: "pointer" }}>
                                Save
                            </button>

                            <button
                                type="button"
                                className="btn btn-danger ms-2"
                                data-bs-dismiss="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/Dashboardview")}
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </form>
            </div>





        </>
    )
}
export default H1BConfig

import React, { useContext, useState, useEffect } from "react";
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../../../Assets/css/style.css";
// import vaanam from "../../../Assets/img/Vaanam.png";
// import login from "../../../Assets/img/login.svg";
import LoginService from "../../../services/LoginService";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "./Changepass.css";
import $ from "jquery";
import Preloader from "../../Preloader";
import LoginView from "../Login/LoginView";

export default function ConfirmPassword() {
  const NewpasswordToggle = () => {
    setPasswordToggle(!Newpasswordtoggle);
  };
  const ConfirmpasswordToggle = () => {
    setConfirmpasswordToggle(!Confirmpasswordtoggle);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [disabled, setDisabled] = useState(false);

  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errNewPassword: "",
    errConfirmPassword: "",
  });
  const [Auth, SetAuth] = useState();

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);
  const { data, setCookie, removeCookie } = useContext(AuthContext);
  const [Newpasswordtoggle, setPasswordToggle] = useState(true);
  const [Confirmpasswordtoggle, setConfirmpasswordToggle] = useState(true);
  const AuthObject = new LoginService();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const newPasswordchange = (e) => {
    var myerror = dummy;

    if (e.target.value == null || e.target.value == "") {
      myerror.errNewPassword = "Please enter new password";
    } else {
      myerror.errNewPassword = "";
    }
    setDummy({ ...myerror });
  };
  const confirmPasswordchange = (e) => {
    var myerror = dummy;

    if (e.target.value == null || e.target.value == "") {
      myerror.errConfirmPassword = "Please enter confirm password";
    } else {
      myerror.errConfirmPassword = "";
      var passwordmatch = document.getElementById("newpassword").value;
      if (passwordmatch != null && passwordmatch != "") {
        if (e.target.value != passwordmatch) {
          myerror.errConfirmPassword = "Password do not match";
        } else {
          myerror.errConfirmPassword = "";
        }
      }
    }
    setDummy({ ...myerror });
  };
  const ChangepassSubmit = (e) => {
    e.preventDefault();
    var myerror = dummy;

    var flag = true;

    const obj = new FormData(e.currentTarget);
    var data = {
      confirmPassword: e.currentTarget.ConfirmPassword.value,
      newPassword: e.currentTarget.NewPassword.value,
      userID: parseInt(cookies.get("userID")),
    };
    if (data.newPassword == null || data.newPassword == "") {
      myerror.errNewPassword = "Please enter new password";
      flag = false;
    } else {
      myerror.errNewPassword = "";
    }
    if (data.confirmPassword == null || data.confirmPassword == "") {
      myerror.errConfirmPassword = "Please enter confirm password";
      flag = false;
    } else {
      myerror.errConfirmPassword = "";
    }
    if (
      data.newPassword != null &&
      data.newPassword != "" &&
      data.confirmPassword != null &&
      data.confirmPassword != ""
    ) {
      if (data.confirmPassword != data.newPassword) {
        myerror.errConfirmPassword = "Password do not match";
        flag = false;
      } else {
        myerror.errConfirmPassword = "";
      }
    }
    setDummy({ ...myerror });
    if (flag == true) {
      Changepassword(data);
    }
  };
  const Changepassword = (data) => {
    AuthObject.Changepassword(data)
      .then(
        (res) => (
          swal({
            text: "Password Changed",
            icon: "success",
          }),
          cookies.remove("userID"),
          setTimeout(() => {
            window.location.href = "/Admin";
          }, 2000),
          setDisabled(false)
        )
      )
      .catch((res) => {
        swal({
          text: "Something went wrong!",
          icon: "warning",
        });
        setDisabled(false);
      });
  };
  const Back = () => {
    navigate(-1);
  };
  const [showComponent, setShowComponent] = useState(false); // State to manage delay

  useEffect(() => {
    import("../Css/styleVt.css");
    import("../Css/bootsnav.css");

    // Delay for 1 second before showing the component
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 100);
  });

  if (!showComponent) {
    return null; // Or return a loading spinner, etc.
  }

  return Auth ? (
    <>
      <Preloader />
      <div
        className="login-area bg-gray"
        // style={{ height: "794px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <form
                action="#"
                id="login-form"
                onSubmit={ChangepassSubmit}
                className="white-popup-block"
                autocomplete="off"
              >
                <div className="row align-center">
                  <div className="col-md-5 login-social">
                    {/* <img src="/assets/img/LG_logo.png" alt="Logo" style={{ height: "60px" }} />{" "}
                    <br></br>
                    <img src="/assets/img/login.svg" alt="Login" style={{ height: "263px" }} /> */}
                    <img
                      src="/assets/img/LG_logo_newBG.png"
                      alt="Login"
                      style={{ height: "263px", paddingBottom: "15px" }}
                    />
                  </div>
                  <div className="col-md-7 login-custom">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>New Password</h6>
                          </label>
                          <input
                            type={Newpasswordtoggle ? "Password" : "text"}
                            className="form-control"
                            placeholder="New Password"
                            id="newpassword"
                            name="NewPassword"
                            maxLength={50}
                            // {...register("NewPassword", {
                            //   required: "New Password is Required",
                            // })}
                            value={register.NewPassword}
                            onKeyDown={(event) => onKeyDown(event)}
                            onChange={(e) => newPasswordchange(e)}
                            // onChange={() => clearErrors("Password")}
                            // onChange={() => setError("Password", "")}
                          />
                          <span className="text-danger">
                            {dummy.errNewPassword}
                          </span>
                          <span className="password-icon">
                            <span onClick={() => NewpasswordToggle()}>
                              {Newpasswordtoggle ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>Confirm Password</h6>
                          </label>
                          <input
                            type={Confirmpasswordtoggle ? "password" : "text"}
                            className="form-control"
                            placeholder="Confirm Password"
                            id="confirmpassword"
                            maxLength={50}
                            name="ConfirmPassword"
                            onChange={(e) => confirmPasswordchange(e)}
                            // {...register("ConfirmPassword", {
                            //   required: "Confirm Password is Required",
                            // })}
                            value={register.ConfirmPassword}
                            onKeyDown={(event) => onKeyDown(event)}
                            // onChange={() => clearErrors("Password")}
                            // onChange={() => setError("Password", "")}
                          />
                          <span className="text-danger">
                            {dummy.errConfirmPassword}
                          </span>
                          <span className="password-icon">
                            <span onClick={() => ConfirmpasswordToggle()}>
                              {Confirmpasswordtoggle ? (
                                <FaEyeSlash />
                              ) : (
                                <FaEye />
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-6">
                          <button type="submit">Submit</button>
                        </div>
                        <div className="col-6">
                          <button id="c" type="button" onClick={Back}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoginView />
  );
}

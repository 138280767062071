import React, { useState, useEffect, useReducer } from "react";
import CookieManager, { addProduct } from "../common/CookieManager";
import { useCookies } from "react-cookie";
import ProjectServices from "../../../services/ProjectServices";
import swal from "sweetalert";
import Preloader from "../../Preloader";

var ProjectServicesObj = new ProjectServices();

function ShoppingCart() {
  const [ProjectDetails, setProjectDetails] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["products"]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    cartdata: [{ name: "", quantity: 0 }],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      $("#preloader").hide();
    }, 1500);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCookieData = async () => {
      try {
        const res = cookies.products;
        if (res && res.length > 0) {
          setProjectDetails(res);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchCookieData();
    console.log("Test11111111111111");
  }, [cookies.products]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^[0-9]{10}$/;
    return re.test(String(phone));
  };

  const handleSubmit = (e) => {
    //
    e.preventDefault();
    const { name, email, phone, companyName } = formData;
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }
    if (!phone) {
      newErrors.phone = "Phone is required";
    } else if (!validatePhone(phone)) {
      newErrors.phone = "Phone number must be 10 digit Numbers only";
    }
    if (!companyName) newErrors.companyName = "Company Name is required";

    if (Object.keys(newErrors).length === 0) {
      // const cartDetails = cookies.products;
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   cartdata: ProjectDetails
      // }));

      var data = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        companyName: formData.companyName,
        cartdata: ProjectDetails,
      };
      const res = ProjectServicesObj.ProcessCart(data);
      if (res) {
        removeAllCookies();
        setProjectDetails([]);
        swal({
          title: "Your Cart is Shared!",
          icon: "success",
          timer: 2000,
          buttons: false,
          text: "",
        });
      }
      setFormData({
        name: "",
        email: "",
        phone: "",
        companyName: "",
        cartdata: [{ name: "", quantity: 0 }],
      });
      document.getElementById("modalCloseButton").click();
    } else {
      setErrors(newErrors);
    }
  };

  const removeAllCookies = () => {
    removeCookie("products", { path: "/" });
  };

  // Increase the counter
  const increaseCount = (index) => {
    //
    const updatedProducts = [...ProjectDetails];
    updatedProducts[index].quantity += 1;
    setProjectDetails(updatedProducts);
    setCookie("products", JSON.stringify(updatedProducts), { path: "/" });
    // setPagearender();
    // window.location.reload();
  };

  // Decrease the counter
  const decreaseCount = (index) => {
    //
    const updatedProducts = [...ProjectDetails];
    if (updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
      setProjectDetails(updatedProducts);
      setCookie("products", JSON.stringify(updatedProducts), { path: "/" });
      // window.location.reload();
    }
  };

  // Remove a product
  const removeProduct = (index) => {
    //
    const updatedProducts = ProjectDetails.filter((_, i) => i !== index);
    setProjectDetails(updatedProducts);
    setCookie("products", JSON.stringify(updatedProducts), { path: "/" });
  };

  return (
    <>
      <Preloader />
      <div className="container-fluid">
        <div className="row px-xl-5">
          <div className="col-lg-12 table-responsive mb-5">
            <table className="table table-light table-borderless table-hover text-center mb-0">
              <thead className="thead-dark">
                <tr>
                  <th>Products</th>
                  {/* <th>Price</th> */}
                  <th>Quantity</th>
                  {/* <th>Total</th> */}
                  <th>Remove</th>
                </tr>
              </thead>

              <tbody className="align-middle">
                {ProjectDetails != null && ProjectDetails.length > 0 ? (
                  ProjectDetails.map((v, i) => {
                    //
                    return (
                      <tr key={i + 1}>
                        <td className="align-middle" style={{ width: "60%" }}>
                          {/* <img src="img/product-1.jpg" alt="" style={{width: '50px'}} /> */}
                          {v.name}
                        </td>
                        {/* <td className="align-middle">$150</td> */}
                        <td className="align-middle" style={{ width: "20%" }}>
                          <div
                            className="input-group quantity mx-auto"
                            style={{ width: "100px" }}
                          >
                            <div className="input-group-btn">
                              <button
                                className="btn btn-sm btn-primary btn-minus"
                                onClick={() => decreaseCount(i)}
                              >
                                <i className="fa fa-minus" />
                              </button>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-sm bg-secondary border-0 text-center"
                              value={v.quantity}
                            />
                            <div className="input-group-btn">
                              <button
                                className="btn btn-sm btn-primary btn-plus"
                                onClick={() => increaseCount(i)}
                              >
                                <i className="fa fa-plus" />
                              </button>
                            </div>
                          </div>
                        </td>
                        {/* <td className="align-middle">$150</td> */}
                        <td className="align-middle" style={{ width: "20%" }}>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => removeProduct(i)}
                          >
                            <i className="fa fa-times" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="3">No products available.</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>

            <button
              className="btn btn-block btn-primary font-weight-bold my-3 py-3"
              data-toggle="modal"
              data-target="#myModal"
              disabled={ProjectDetails.length === 0}
            >
              Proceed To Confirm
            </button>

            {/* Modal content start */}
            <div id="myModal" className="modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header bannerColorChange">
                    {/* <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button> */}
                    <h4 className="modal-title text-light">Submit Enquiry</h4>
                  </div>
                  <div className="modal-body">
                    <form
                      onSubmit={handleSubmit}
                      className="contact-form"
                      autoComplete="off"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              type="text"
                              value={formData.name}
                              onChange={handleChange}
                              // onChange={name}
                            />
                            {errors.name && (
                              <span
                                className="text-danger"
                                style={{ textAlign: "left", display: "block" }}
                              >
                                {errors.name}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              type="email"
                              value={formData.email}
                              onChange={handleChange}

                              // onChange={email}
                            />
                            {errors.email && (
                              <span
                                className="text-danger"
                                style={{ textAlign: "left", display: "block" }}
                              >
                                {errors.email}
                              </span>
                            )}
                            {/* <span className="text-danger"> {error.email} </span> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              id="phone"
                              name="phone"
                              placeholder="Phone*"
                              type="tel"
                              maxLength={10}
                              value={formData.phone}
                              onChange={handleChange}
                              // onKeyDown={(event) => onkeydown(event)}
                              // onChange={phone}
                              // onChange={phone => numberOnly("phone")}
                            />
                            {errors.phone && (
                              <span
                                className="text-danger"
                                style={{ textAlign: "left", display: "block" }}
                              >
                                {errors.phone}
                              </span>
                            )}
                            {/* <span className="text-danger"> {error.phone} </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="companyName"
                              id="companyName"
                              placeholder="Company Name and Address*"
                              type="text"
                              value={formData.companyName}
                              onChange={handleChange}
                            ></textarea>
                            {errors.companyName && (
                              <span
                                className="text-danger"
                                style={{ textAlign: "left", display: "block" }}
                              >
                                {errors.companyName}
                              </span>
                            )}
                            {/* <input
                          className="form-control"
                          id="companyName"
                          name="companyName"
                          placeholder="Company Name*"
                          type="text"
                          value={formData.companyName}
                          onChange={handleChange}
                        /> */}
                            {/* <span className="text-danger">
                          {" "}
                          {error.companyName}{" "}
                        </span> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group comments">
                            {/* <textarea
                          className="form-control"
                          id="project"
                          name="project"
                          placeholder="Project Requirement*"
                          defaultValue={""}
                          onChange={project}
                        />
                        <span className="text-danger"> {error.project} </span> */}
                          </div>
                        </div>
                      </div>
                      {/* <input type="text" id="name" className="input"/>
                    <reCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    ref={captchaRef}
                    /> */}
                      <div className="row">
                        <div className="col-lg-12">
                          {/* {!isLoaded ? (
                        <button type="submit" name="submit" id="submit">
                          Send Message <i className="fa fa-paper-plane" />
                        </button>
                      ) : (
                        <button class="btn btn-primary" type="button" disabled>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      )} */}
                        </div>
                      </div>
                      {/* Alert Message */}
                      {/* <div className="col-lg-12 alert-notification">
                    <div id="message" className="alert-msg" />
                  </div> */}

                      <div className="modal-footer">
                        <button
                          type="submit"
                          // className="rounded rounded-4 "
                          className="btn btn-default btn-danger text-light rounded rounded-4 border border-3 "
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-default btn-danger text-light rounded rounded-4 border border-3 "
                          id="modalCloseButton"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="modal-footer bannerColorChange"></div> */}
                </div>
              </div>
            </div>
            {/* Modal content end */}
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default ShoppingCart;

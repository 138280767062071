import React from 'react';
import { useCookies } from 'react-cookie';
import { setCookie, getCookie } from 'react-cookie';


const CookieManager = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['products']);

  const addProduct = (name, quantity) => {
    const products = cookies.products || [];
    const productIndex = products.findIndex(product => product.name === name);

    if (productIndex === -1) {
    const newProduct = { name, quantity: parseInt(quantity) };
    const updatedProducts = [...products, newProduct];
    const stringfyProducts=  JSON.stringify(updatedProducts)
    setCookie('products',stringfyProducts, { path: '/' });
    return true;
    }else{
      if (quantity > 1) {
        products[productIndex].quantity = parseInt(quantity);
        const stringifiedProducts = JSON.stringify(products);
        setCookie('products', stringifiedProducts, { path: '/' });
    }
    return false;
  };
}

  const getProducts = () => {
    return cookies.products ? JSON.parse(cookies.products) : [];
  };


  const removeProduct = (index) => {
    const products = cookies.products ? JSON.parse(cookies.products) : [];
    const updatedProducts = products.filter((_, i) => i !== index);
    setCookie('products', JSON.stringify(updatedProducts), { path: '/' });
  };

  const updateProductQuantity = (index, quantity) => {
    const products = cookies.products ? JSON.parse(cookies.products) : [];
    products[index].quantity = quantity;
    setCookie('products', JSON.stringify(products), { path: '/' });
  };

  const removeAllCookies = () => {
    Object.keys(cookies).forEach(cookieName => {
      removeCookie(cookieName, { path: '/' });
    });
  };

  // return children({ addProduct, getProducts, removeProduct });
  return children({ addProduct, getProducts, updateProductQuantity, removeProduct ,removeAllCookies});
};

export default CookieManager;

import React, { memo, useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Footer from "./Footer";
import { BackToTop } from "./Footer";
import { useCookies } from "react-cookie";
import CookieManager from "./CookieManager";
import CommonServices from "../../../services/CommonServices";
import { useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';

const NavBar = () => {
  const [cartCount, setCartCount] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(["products"]);
  const [Category, setCategory] = useState([]);
  var CommonServicesObj = new CommonServices();

  useEffect(() => {
    CommonServicesObj.GetCategoryList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setCategory([...res.data]);
      }
    });
  }, []);

  useEffect(() => {
    const fetchCookieData = async () => {
      try {
        const res = cookies.products;
        if (res && res.length > 0) {
          setCartCount(res.length);
          console.log(res.length);
        } else {
          setCartCount(0);
        }
      } catch (error) {
        console.error("Error fetching products count:", error);
      }
    };
    fetchCookieData();
  }, [cookies]);

  const myData = {
    flag: true,
    price: 123,
  };
  const myData1 = {
    flag: false,
    price: 123,
  };

  const navigate = useNavigate();
  const handleCategoryNavigate = (id) => {
    navigate("/shop", {
      state: { categoryID: id, flag: false },
      replace: true,
    });
  };

  function showDropdown() {
    const dropdown = document.getElementById("navbar-vertical");
    dropdown.style.display = "block";
  }

  function hideDropdown() {
    const dropdown = document.getElementById("navbar-vertical");
    dropdown.style.display = "none";
  }

  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid">
        {/* <div className="row bg-secondary py-1 px-xl-5">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="d-inline-flex align-items-center h-100">
                <a className="text-body mr-3" href>About</a>
                <a className="text-body mr-3" href>Contact</a>
                <a className="text-body mr-3" href>Help</a>
                <a className="text-body mr-3" href>FAQs</a>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="d-inline-flex align-items-center">
                <div className="btn-group">
                  <button type="button" className="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">My Account</button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item" type="button">Sign in</button>
                    <button className="dropdown-item" type="button">Sign up</button>
                  </div>
                </div>
                <div className="btn-group mx-2">
                  <button type="button" className="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">USD</button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item" type="button">EUR</button>
                    <button className="dropdown-item" type="button">GBP</button>
                    <button className="dropdown-item" type="button">CAD</button>
                  </div>
                </div>
                <div className="btn-group">
                  <button type="button" className="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">EN</button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item" type="button">FR</button>
                    <button className="dropdown-item" type="button">AR</button>
                    <button className="dropdown-item" type="button">RU</button>
                  </div>
                </div>
              </div>
              <div className="d-inline-flex align-items-center d-block d-lg-none">
                <a href className="btn px-0 ml-2">
                  <i className="fas fa-heart text-dark" />
                  <span className="badge text-dark border border-dark rounded-circle" style={{paddingBottom: '2px'}}>0</span>
                </a>
                <a href className="btn px-0 ml-2">
                  <i className="fas fa-shopping-cart text-dark" />
                  <span className="badge text-dark border border-dark rounded-circle" style={{paddingBottom: '2px'}}>0</span>
                </a>
              </div>
            </div>
          </div> */}
        <div className="  row align-items-center bg-light  px-xl-1 d-none d-lg-flex">
          <div className="col-lg-4 " style={{ paddingLeft: "120px" }}>
            {/* <a href className="text-decoration-none">
                <span className="h1 text-uppercase text-primary bg-dark px-2">EMC</span>
                <span className="h1 text-uppercase text-dark bg-primary px-2 ml-n1">Gear</span>
              </a> */}
            <img
              className="img-fluid float-left"
              style={{ width: "100px", height: "100px" }}
              // src="/assets/img/LG_logo-noBG.png"
              src="/assets/img/LG_logo_newBG.png"
              alt=""
            />
          </div>
          <div className="col-lg-4 col-6 text-left">
            {/* <p className="m-0">Customer Service</p>
            <h5 className="m-0">+012 345 6789</h5> */}
          </div>
          <div className="col-lg-4 col-6 text-right">
            {/* <form
            // action
            >
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for products"
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-transparent text-primary">
                    <i className="fa fa-search" />
                  </span>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <div className="container-fluid bg-dark mb-30">
        <div className="row px-xl-5">
          <div className="col-lg-3 d-none d-lg-block">
            <div
              className="btn d-flex align-items-center justify-content-between bg-primary w-100"
              style={{ height: "65px", padding: "0 30px", cursor: "pointer" }}
              onMouseEnter={() => showDropdown()}
              onMouseLeave={() => hideDropdown()}
            >
              <h6 className="text-dark m-0">
                <i className="fa fa-bars mr-2" />
                Categories
              </h6>
              <i className="fa fa-angle-down text-dark" />
            </div>

            <nav
              className="navbar navbar-vertical navbar-light  p-0 bg-light"
              id="navbar-vertical"
              style={{
                width: "calc(100% - 30px)",
                zIndex: 999,
                position: "absolute",
                display: "none",
              }}
              onMouseEnter={() => showDropdown()}
              onMouseLeave={() => hideDropdown()}
            >
              <div className="navbar-nav w-100 ">
                <p></p>
                {Category.map((category) => (
                  <a
                    //href
                    onClick={() => handleCategoryNavigate(category.categoryID)}
                    className="nav-item nav-link d-flex justify-content-start"
                    key={category.categoryID}
                    style={{ cursor: "pointer" }}
                  >
                    {category.categoryName}
                  </a>
                  // <Link to={"/shop"} className="nav-item nav-link">Products</Link>
                ))}
              </div>
            </nav>

            <nav
              className="navbar navbar-vertical navbar-light align-items-start p-0 bg-light"
              id="navbar-vertical"
              style={{
                width: "calc(100% - 30px)",
                zIndex: 999,
                position: "absolute",
                left: 0, // Aligns the dropdown to the left
                display: "none",
              }}
              onMouseEnter={() => showDropdown()}
              onMouseLeave={() => hideDropdown()}
            >
              <div className="navbar-nav w-100 justify-content-start">
                {Category.map((category) => (
                  <a
                    //href
                    onClick={() => handleCategoryNavigate(category.categoryID)}
                    className="nav-item nav-link"
                    key={category.categoryID} // Added a key to each item for uniqueness
                  >
                    {category.categoryName}
                  </a>
                ))}
              </div>
            </nav>
          </div>

          <div className="col-lg-9">
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-3">
              <div className="text-decoration-none d-block d-lg-none headderColorChange bg-white">
                {/* <a href className="text-decoration-none">
                <span className="h1 text-uppercase text-primary bg-dark px-2">EMC</span>
                <span className="h1 text-uppercase text-dark bg-primary px-2 ml-n1">Gear</span>
              </a> */}
                <img
                  className="img-fluid float-left"
                  style={{ width: "80px", height: "80px" }}
                  // src="/assets/img/LG_logo-noBG.png"
                  src="/assets/img/LG_logo_newBG.png"
                  alt=""
                />
              </div>
              {/* <a
                //href
                className="text-decoration-none d-block d-lg-none headderColorChange"
              >
                <span className="h1 text-uppercase text-dark bg-light px-2 headderColorChange">
                  EMC
                </span>
                <span className="h1 text-uppercase text-light bg-primary px-2 ml-n1">
                  Gear
                </span>
              </a> */}
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div className="navbar-nav mr-auto py-0">
                  <Link to="/" className="nav-item nav-link">
                    Home
                  </Link>
                  <Link
                    to={{
                      replace: true,
                      pathname: "/shop",
                    }}
                    state={myData}
                    className="nav-item nav-link"
                  >
                    Categories
                  </Link>

                  <Link
                    to={"/shop"}
                    state={myData1}
                    className="nav-item nav-link"
                  >
                    Products
                  </Link>
                  {/* <Link to={"/shop"} className="nav-item nav-link">
                    Products
                  </Link> */}
                  <Link to={"/cart"} className="nav-item nav-link">
                    Cart
                  </Link>
                  <Link to={"/contact"} className="nav-item nav-link">
                    Contact us
                  </Link>
                </div>
                <div className="navbar-nav ml-auto py-0 d-none d-lg-block">
                  {/* <a href className="btn px-0">
                      <i className="fas fa-heart text-primary" />
                      <span className="badge text-secondary border border-secondary rounded-circle"  style={{paddingBottom: '2px', marginLeft: '5px'}}>2</span>
                    </a> */}
                  <a href="/cart" className="btn px-0 ml-3">
                    <i className="fas fa-shopping-cart text-primary"></i>
                    <span
                      className="badge text-secondary border border-secondary rounded-circle"
                      style={{ paddingBottom: "2px", marginLeft: "5px" }}
                    >
                      {cartCount}
                    </span>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* Navbar End */}
      <div>
        <Outlet />
      </div>
      <Footer />
      <BackToTop />
    </>
  );
};

export default memo(NavBar);

import { event } from 'jquery';
import React,{memo} from 'react'

function UserRegistrationForm() {

    const allowOnlyAlphabets = (event)=> {
        const input = event.target;
        const value = input.value;
        input.value = value.replace(/[^a-zA-Z\s]/g, '');
        console.log("ok");
      }

  return (
    <>
<div className="container mt-5">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <h2 className="mb-4 text-center">User Registration</h2>
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstName">First Name</label>
              <input type="text" className="form-control" id="firstName" name="firstName" required onInput={allowOnlyAlphabets} />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastName">Last Name</label>
              <input type="text" className="form-control" id="lastName" name="lastName" required onInput={allowOnlyAlphabets} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">Email Address</label>
              <input type="email" className="form-control" id="email" name="email" required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phone">Phone Number</label>
              <input type="tel" className="form-control" id="phone" name="phone" required />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="password">Password</label>
              <input type="password" className="form-control" id="password" name="password" required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" required />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input type="text" className="form-control" id="companyName" name="companyName" required />
          </div>
          <div className="form-group">
            <label htmlFor="companyAddress">Company Address</label>
            <input type="text" className="form-control" id="companyAddress" name="companyAddress" required />
          </div>
          <div className="form-group">
            <label htmlFor="companyWebsite">Company Website</label>
            <input type="url" className="form-control" id="companyWebsite" name="companyWebsite" />
          </div>
          <div className="form-group">
            <label htmlFor="industryType">Industry Type</label>
            <select className="form-control" id="industryType" name="industryType" required>
              <option value="manufacturing">Manufacturing</option>
              <option value="testing">Testing Laboratories</option>
              <option value="research">Research Institutions</option>
              <option value="automotive">Automotive Industry</option>
              <option value="aerospace">Aerospace Industry</option>
              <option value="defense">Defense Contractors</option>
              <option value="medical">Medical Device Manufacturers</option>
              <option value="consultants">Consultants and Engineers</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="jobTitle">Job Title/Position</label>
            <input type="text" className="form-control" id="jobTitle" name="jobTitle" required />
          </div>
          <div className="form-group">
            <label htmlFor="contactMethod">Preferred Contact Method</label>
            <select className="form-control" id="contactMethod" name="contactMethod" required>
            <option value="">--Select method of contact--</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
          </div>
          
          <div className="form-group">
            <label htmlFor="productsOfInterest">Products of Interest</label>
            <select className="form-control" id="productsOfInterest" name="productsOfInterest"  required>
              <option value="">--Select interest--</option>
              <option value="emc">EMC Equipment</option>
              <option value="mechanical">Mechanical Equipment</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="referral">How Did You Hear About Us?</label>
            <input type="text" className="form-control" id="referral" name="referral" />
          </div>
          <div className="form-check">
            <input type="checkbox" className="form-check-input" id="terms" name="terms" required />
            <label className="form-check-label" htmlFor="terms">Agree to Terms and Conditions</label>
          </div>
          <div className="form-check">
            <input type="checkbox" className="form-check-input" id="newsletter" name="newsletter" />
            <label className="form-check-label" htmlFor="newsletter">Subscribe to Newsletter</label>
          </div>
          <button type="submit" className="btn btn-primary mt-3">Register</button>
        </form>
      </div>
    </div>
  </div>
    </>
  )
}

export default memo (UserRegistrationForm)
